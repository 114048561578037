import { NEW_COMPANY_CATEGORY_FIELDS } from '../../utils/const'

export const FETCH_COMPANIES = 'FETCH_COMPANIES'
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR'

export const FETCH_COMPANY = 'FETCH_COMPANY'
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR'

export const FETCH_FIELDS = 'FETCH_FIELDS'
export const FETCH_FIELDS_SUCCESS = 'FETCH_FIELDS_SUCCESS'
export const FETCH_FIELDS_ERROR = 'FETCH_FIELDS_ERROR'

export const PATCH_COMPANY = 'PATCH_COMPANY'
export const PATCH_COMPANY_SUCCESS = 'PATCH_VACANCY_COMPANY'
export const PATCH_COMPANY_ERROR = 'PATCH_VACANCY_COMPANY'

export const POST_COMPANY = 'POST_COMPANY'
export const POST_COMPANY_SUCCESS = 'POST_VACANCY_COMPANY'
export const POST_COMPANY_ERROR = 'POST_VACANCY_COMPANY'

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_COMPANY'
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_COMPANY'

const initialState = {
    companies: [],
    company: {},
    error: null,
    fields: NEW_COMPANY_CATEGORY_FIELDS,
    categories: [],
    isLoading: false,
    isCompaniesLoading: false,
    isCompanyLoading: false,
    isFieldsLoading: false,
    isCategoriesLoading: false,
}

export const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANIES:
            return {...state, isCompaniesLoading: true}
        case FETCH_COMPANIES_SUCCESS:
            return {...state, isCompaniesLoading: false, companies: action.payload}
        case FETCH_COMPANIES_ERROR:
            return {...state, isCompaniesLoading: false, error: action.payload}
        case FETCH_COMPANY:
            return {...state, isCompanyLoading: true}
        case FETCH_COMPANY_SUCCESS:
            return {...state, isCompanyLoading: false, company: action.payload}
        case FETCH_COMPANY_ERROR:
            return {...state, isCompanyLoading: false, error: action.payload}
        case FETCH_FIELDS:
            return {...state, isFieldsLoading: true}
        case FETCH_FIELDS_SUCCESS:
            return {...state, isFieldsLoading: false, fields: action.payload}
        case FETCH_FIELDS_ERROR:
            return {...state, isFieldsLoading: false, error: action.payload}
        case PATCH_COMPANY:
            return {...state, isLoading: true,}
        case PATCH_COMPANY_SUCCESS:
            return {...state, isLoading: false}
        case PATCH_COMPANY_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case POST_COMPANY:
            return {...state, isLoading: true}
        case POST_COMPANY_SUCCESS:
            return {...state, isLoading: false}
        case POST_COMPANY_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case FETCH_CATEGORIES:
            return {...state, isCategoriesLoading: true,}
        case FETCH_CATEGORIES_SUCCESS:
            return {...state, isCategoriesLoading: false, categories: action.payload}
        case FETCH_CATEGORIES_ERROR:
            return {...state, isCategoriesLoading: false, error: action.payload}
        default:
            return state
    }
}
