import React, { useState, useEffect } from 'react';
import {
    Typography,
	List,
	ListItemButton,
	ListItemText,
	Collapse,
	Grid,
    Button
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchApplicants } from '../../../../store/action-creators/applicant';
import { fetchUser } from '../../../../store/action-creators/user';

import M2ALoader from "../../../templateParts/loader";
import ApplicantCard from './applicant-card';


const selectApplicants = store => store.applicant.applicants
const selectIsLoading = store => store.applicant.isLoading
const selectCompanyInfo = store => store.user.user

const Applicants = () => {
    const { t } = useTranslation()
    const applicants = useSelector(selectApplicants)
    const companyInfo = useSelector(selectCompanyInfo)
    const isLoading = useSelector(selectIsLoading)

    const dispatch = useDispatch()
	const [openResponses, setOpenResponses] = useState({})

    useEffect(() =>{
        dispatch(fetchApplicants())
        dispatch(fetchUser())
    }, [dispatch])
	
	const toggleResponsesList = (vacancyId) => {
		setOpenResponses((prevOpenResponses) => ({
			...prevOpenResponses,
			[vacancyId]: !prevOpenResponses[vacancyId],
		}));
	};

    const updateApplicants = () => {
        dispatch(fetchApplicants())
    }

	if (isLoading) {
		return <M2ALoader />
	}

  	return (
		<Grid container  sx={{  marginTop: '20px' }}>
			{applicants.map((vacancy) => (
                vacancy.count_applicants > 0 
                ? (
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="vacancy-responses"
                        key={vacancy.job_id}
                    >
                        <ListItemButton onClick={() => toggleResponsesList(vacancy.job_id)}>
                            <ListItemText primary={vacancy.isDeleted ? `${vacancy.job_title} (Deleted)` : vacancy.job_title} />
                            {openResponses[vacancy.job_id] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openResponses[vacancy.job_id]} timeout="auto" unmountOnExit>
                            {vacancy.applicants.map((response) => (
                                (vacancy.applicants.length) ? <ApplicantCard key={response.ID} response={response} company={vacancy} contacts={companyInfo} updateCb={updateApplicants}/> : null
                            ))}
                        </Collapse>
                    </List>
                ) : (
    <               Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                {t('Откликов пока нет, добавьте больше вакансий')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant='contained'
                                sx={{ mt: 3, mb: 2 }}
                                onClick={() => window.location.replace('/profile/career/new-vacancy')}
                            >
                                {t('add')}
                            </Button>
                        </Grid>
                    </Grid>
                )
            ))}
		</Grid>
	);
};

export default Applicants;