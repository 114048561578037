import {Box, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
// import MyVacancies from "../profile/career/my-vacancies";

const M2ALoader = () => {
	return (
		<Box sx={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px', alignItems: 'center'}}>
			<Box>
				<CircularProgress sx={{width: '200px', color: '#f98b27'}} size={50}/>
			</Box>
		</Box>
	)
}

export default M2ALoader;