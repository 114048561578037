// import axios from 'axios';
import axios from '../store/axios-config';

import { SERVER_URL } from './const';

const getTermSelectOptions = async (taxonomyTerms) => {
    const token = localStorage.getItem('token');
    const termsString = taxonomyTerms.join('&term[]=');



        try {
            const response = await axios.get(`${SERVER_URL}/acccompany/v1/my-company/term_select?term[]=${termsString}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            return {};
        }
    
};

export const fetchOptions = async (fields, setOptions) => {
    const taxonomyTerms = [];
  
    for (const fieldName in fields) {
        const field = fields[fieldName];
        if (field.type === 'term-select') {
            taxonomyTerms.push(field.taxonomy);
        }
    }

    const updatedOptions = await getTermSelectOptions(taxonomyTerms);

    setOptions(updatedOptions);
};
