import axios from 'axios';
import i18n from 'i18next';

const axiosInstance = axios.create({

});



axiosInstance.interceptors.request.use((config) => {
  let language = i18n.language || 'en_US';

  const languageMap = {
    'en': 'en_US',
    'ru': 'ru_RU',
    'am': 'hy',
  };

  if (languageMap[language]) {
    language = languageMap[language];
  } else {
    language = 'en_US';
  }

  config.headers['Accept-Language'] = language;




  return config;
});

export default axiosInstance;
