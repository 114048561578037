export const FETCH_APPLICANTS = 'FETCH_APPLICANTS'
export const FETCH_APPLICANTS_SUCCESS = 'FETCH_APPLICANTS_SUCCESS'
export const FETCH_APPLICANTS_ERROR = 'FETCH_APPLICANTS_ERROR'

export const PATCH_APPLICANT = 'PATCH_APPLICANT'
export const PATCH_APPLICANT_SUCCESS = 'PATCH_APPLICANT_SUCCESS'
export const PATCH_APPLICANT_ERROR = 'PATCH_APPLICANT_ERROR'

const initialState = {
    applicants: [],
    isLoading: false,
}

export const applicantReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_APPLICANTS:
            return {...state, isLoading: true}
        case FETCH_APPLICANTS_SUCCESS:
            return {...state, isLoading: false, applicants: action.payload}
        case FETCH_APPLICANTS_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case PATCH_APPLICANT:
            return {...state, isLoading: true}
        case PATCH_APPLICANT_SUCCESS:
            return {...state, isLoading: false}
        case PATCH_APPLICANT_ERROR:
            return {...state, isLoading: false}
        default:
            return state
    }
}