import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import globalcss from './css/Global.module.scss'

import App from './app';
// import reportWebVitals from './reportWebVitals';
// import Store from './store/store';
import './i18n';
import { store } from './store';

// const store = new Store();

// export const Context = createContext({
//     store,
// })


const root = ReactDOM.createRoot(document.getElementById('root'));
document.title = "Company Profile | Move2Armenia";

root.render(
  	// <Context.Provider value={{store}}>
		<Provider store={store}>
			<div className={globalcss.root}>
      			<App/>
			</div>
		</Provider>

  	// </Context.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
