import { Grid, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NoCompanySection = () => {
    const { t } = useTranslation()

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>
                    {t('Для размещения вакансии необходимо создать компанию')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => window.location.replace('/profile/catalog')}
                >
                    {t('Создать')}
                </Button>
            </Grid>
        </Grid>
    )
}

export default NoCompanySection