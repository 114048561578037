// import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { 
    Grid, 
    TextField,
    Button,  
    CardContent,
    Card,
    Typography
} from '@mui/material';

import classes from '../../css/Auth.module.scss'
import logInValidate from '../../utils/validate-rules/log-in-validate-rules';
import useForm from '../../hooks/use-form';
import {useDispatch, useSelector} from 'react-redux';

import { fetchToken } from '../../store/action-creators/auth';
import CircularProgress from "@mui/material/CircularProgress";

const selectIsLoadingAuth = state => state.auth.isLoadingAuth;

const LogIn = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const onSubmit = () => {
        dispatch(fetchToken(values.logInEmail, values.logInPassword))
    }
    const isLoadingAuth = useSelector(selectIsLoadingAuth)

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(() =>  onSubmit(), logInValidate);

    return(
        <Grid item md={6}>
            <Card sx={{ minWidth: 275, borderRadius: '13px', boxShadow: 'none',  }}
                className={classes.AuthContainer_AuthCard}
            >
                <CardContent sx={{padding: '24px'}}>
                <Typography component="div" variant="h5">{t('signIn')}</Typography>
                <TextField
                    required
                    fullWidth
                    margin='normal'
                    variant='filled'
                    id='logInEmail'
                    label={t('email')}
                    name='logInEmail'
                    type='email'
                    onChange={handleChange}
                    className={classes.AuthContainer_AuthCard__input}
                    value={values.logInEmail || ''}
                    helperText={errors.logInEmail}
                    error={Boolean(errors.logInEmail)}
                />
                <TextField
                    required
                    fullWidth
                    margin='normal'
                    variant='filled'
                    id='logInPassword'
                    label={t('password')}
                    name='logInPassword'
                    type='password'
                    onChange={handleChange}
                    className={classes.AuthContainer_AuthCard__input}
                    value={values.logInPassword || ''}
                    helperText={errors.logInPassword}
                    error={Boolean(errors.logInPassword)}
                />
                <Button
                    fullWidth
                    variant='contained'
                    sx={{ mt: 3, mb: 2, color: 'white', position: 'relative' }}
                    onClick={handleSubmit}
                    disabled={isLoadingAuth}
                >
                    {isLoadingAuth ? <CircularProgress sx={{  position: 'absolute', left: '10px', color: '#f98b27'  }} size={14} /> : ""}

                    {t('signIn')}
                </Button>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default LogIn;