export const FETCH_TOKEN = 'FETCH_TOKEN'
export const FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS'
export const FETCH_TOKEN_ERROR = 'FETCH_TOKEN_ERROR'

export const REGISTER = 'REGISTER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'

export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const VALIDATE_TOKEN_SUCCES = 'VALIDATE_TOKEN_SUCCES'
export const VALIDATE_TOKEN_ERROR = 'VALIDATE_TOKEN_ERROR'

export const CONFIRM_EMAIL = 'CONFIRM_EMAIL'
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS'
export const CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR'



const initialState = {
    token: null,
    isLoading: false,
    isTokenValid: false,
    error: null,
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TOKEN:
            return {...state, isLoading: false, isLoadingAuth: true}
        case FETCH_TOKEN_SUCCESS:
            return {...state, isLoading: false, isLoadingAuth: false, token: action.payload}
        case FETCH_TOKEN_ERROR:
            return {...state, isLoading: false, isLoadingAuth: false, error: action.payload}
        case VALIDATE_TOKEN:
            return {...state, isLoading: true}
        case VALIDATE_TOKEN_SUCCES:
            return {...state, isLoading: false, isTokenValid: true}
        case VALIDATE_TOKEN_ERROR:
            return {...state, isLoading: false, isTokenValid: false, error: action.payload}
        case CONFIRM_EMAIL:
            return {...state, isLoading: true}
        case CONFIRM_EMAIL_SUCCESS:
            return {...state, isLoading: false}
        case CONFIRM_EMAIL_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case REGISTER:
            return {...state, isLoading: false, isLoadingRegister: true}
        case REGISTER_SUCCESS:
            return {...state, isLoading: false, isLoadingRegister: false}
        case REGISTER_ERROR:
            return {...state, isLoading: false, isLoadingRegister: false, error: action.payload}
        default:
            return state
    }
}