import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationAM from './locales/am/translation.json';
const resources = {
    en: {
        translation: translationEN,
    },
    ru: {
        translation: translationRU,
    },
    am: {
        translation: translationAM,
    },
};
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'en', //default language
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });
export default i18n;