import { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../../../store/action-creators/company';
import { useTranslation } from 'react-i18next';

import CompanyInfoForm from '../company-form/company-info-form';
import NewCategoryForm from './new-category-form';
import CategoryInput from './category-input';

const selectCategories = store => store.company.categories

const NewCompanySection = () => {
    const categories = useSelector(selectCategories)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [selectedCategory, selectCategory] = useState(null);
    const [newCategory, setNewCategory] = useState(null)
    const [isEditorOpened, setIsEditorOpened] = useState(false);
    const [isCategoryAlertOpened, setIsCategoryAlertOpened] = useState(false);

    useEffect(() =>{
        dispatch(fetchCategories())
    }, [dispatch])

    useEffect(() => {
        if (selectedCategory !== null) {
            if (selectedCategory !== 'newCategory') {
                setIsEditorOpened(true)
            }
        }
    },[selectedCategory])

    const handleNewCategorySubmit = (value) => {
        selectCategory(value)
        setIsCategoryAlertOpened(true);
        setIsEditorOpened(true);
    }

    return (
        <>
            {(!isEditorOpened && selectedCategory !== 'newCategory') &&  
                <CategoryInput
                    categories={categories}
                    selectedCategory={selectedCategory}
                    onChange={selectCategory}
                    onSubmit={setIsEditorOpened}
                />
            }
            {selectedCategory === 'newCategory' && 
                <NewCategoryForm
                    onChange={setNewCategory}
                    onSubmit={handleNewCategorySubmit}
                />
            }
            {isEditorOpened && 
                <CompanyInfoForm
                    type={'post'}
                    companyId={null}
                    category={selectedCategory}
                    newCategory={newCategory}
                    onClose={() => setIsEditorOpened(false)}
                />
            }
            <Snackbar open={isCategoryAlertOpened} autoHideDuration={6000} onClose={() => setIsCategoryAlertOpened(false)}>
                <Alert severity="info">
                    {t('categoryUnderConsideration')}
                </Alert>
            </Snackbar>
        </>
    )
}

export default NewCompanySection;