import { combineReducers } from 'redux';

import { companyReducer } from './company-reducer';
import { authReducer } from './auth-reducer';
import { vacancyReducer } from './vacancy-reducer';
import { userReducer } from './user-reducer';
import { applicantReducer } from './applicant-reducer';

export const rootReducer = combineReducers({
    auth: authReducer,
    company: companyReducer,
    vacancy: vacancyReducer,
    user: userReducer,
    applicant: applicantReducer,
})