import i18n from 'i18next';

const signUpValidate = (values) => {
    let errors = {};
  
    switch (true) {
        case !values.signUpCompanyName:
            errors.signUpCompanyName = i18n.t('Company name is required');
            break;
        case !/^[a-zA-Z0-9][a-zA-Z0-9\s]*[a-zA-Z0-9]$/.test(values.companyName):
            errors.signUpCompanyName = i18n.t('companyNameInvalid');
            break;
        case values.signUpCompanyName.length < 3:
            errors.signUpCompanyName = i18n.t('Название компании должно быть длинее трех символов');
        break;
        case !values.signUpEmail:
            errors.signUpEmail = i18n.t('emailRequired');
            break;
        case !/\S+@\S+\.\S+/.test(values.signUpEmail):
            errors.signUpEmail = i18n.t('emailInvalid');
            break;
        case !values.signUpPassword:
            errors.signUpPassword = i18n.t('passwordRequired');
            break;
        case values.signUpPassword.length < 3:
            errors.signUpPassword = i18n.t('passwordLength');
            break;
        case values.signUpConfirmPassword !== values.signUpPassword:
            errors.signUpConfirmPassword = i18n.t('passwordsNotMatch');
            break;
        default:
            break;
    }
  
    return errors;
  };
  
export default signUpValidate;