// import axios from 'axios';
import axios from '../axios-config';
import { SERVER_URL } from '../../utils/const';
import { FETCH_USER, FETCH_USER_ERROR, FETCH_USER_SUCCESS, PATCH_USER, PATCH_USER_ERROR, PATCH_USER_SUCCESS } from '../reducers/user-reducer';


const token = localStorage.getItem('token')

export const fetchUser = () => {
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_USER})
            const response = await axios.get(
                `${SERVER_URL}/acccompany/v1/user`,
                {headers: {Authorization: `Bearer ${token}`}}
            )

            dispatch({
                type: FETCH_USER_SUCCESS,
                payload: response.data,
            })
        } catch (e) {
            dispatch({
                type: FETCH_USER_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const patchUser = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: PATCH_USER})
            await axios.patch(
                `${SERVER_URL}/acccompany/v1/user`,
                data,
                {headers: {Authorization: `Bearer ${token}`}}
            )
            dispatch({
                type: PATCH_USER_SUCCESS
            })
        } catch (e) {
            dispatch({
                type: PATCH_USER_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}
