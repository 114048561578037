import { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, patchUser } from '../../../../store/action-creators/user';
import classesCard from "../../../../css/Card.module.scss";

import {useTranslation} from "react-i18next";

import M2A_LOADER from '../../../templateParts/loader'
import { PHONE_REGEX } from '../../../../utils/const';

const selectUser = state => state.user.user
const selectIsLoading = state => state.user.isLoading

const Contacts = () => {
    const { t } = useTranslation();
    const companyInfo = useSelector(selectUser)
    const isLoadding = useSelector(selectIsLoading)
    const dispatch = useDispatch()

    const [phoneError, setPhoneError] = useState('');
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        required_vacancy_langs: {},
        vacancy_default_langs: [],
    });

    useEffect(() => { 
        dispatch(fetchUser())
        setFormData(companyInfo)
    }, [])

    useEffect(() => {
        const { 
            email,
            phone,
            required_vacancy_langs,
            vacancy_default_langs,
        } = companyInfo;

        if (vacancy_default_langs) {
            setFormData((prevData) => ({
                ...prevData,
                email,
                phone,
                required_vacancy_langs,
                vacancy_default_langs: vacancy_default_langs[0],
            }));
        }
    }, [companyInfo]);

    if(isLoadding || !formData.email) {
	    return <M2A_LOADER />
    }

    const handleFieldChange = (field, value) => {
        if (field === 'phone' && !PHONE_REGEX.test(value)) {
            setPhoneError('Номер телефона должен начинаться с + и может включать только цифры');
        } else {
            setPhoneError('')
        }

        setFormData((prevData) => ({
        ...prevData,
        [field]: value,
        }));
    };

    const reqLangOptions = ['A1 (Beginner)', 'A2 (Elementary)', 'B1 (Intermediate)', 'B2 (Upper-Intermediate)', 'C1 (Advanced)', 'C2 (Proficiency)', 'Native'];

    const handleLangReqChange = (key, value) => {
        setFormData((prevData) => ({
        ...prevData,
        required_vacancy_langs: {
            ...prevData.required_vacancy_langs,
            [key]: value,
        },
        }));
    };

    const handleDefaultLangsChange = (event) => {
        const selectedLangs = event.target.value;
        setFormData((prevData) => ({
        ...prevData,
        vacancy_default_langs: selectedLangs
        }));
    };

    const handleFormSubmit = () => {
        const { email, phone, required_vacancy_langs, vacancy_default_langs } = formData;
        const reqs = [vacancy_default_langs]
        const dataToSend = {
            email,
            phone,
            required_vacancy_langs,
            vacancy_default_langs: reqs,
        };
        
        if (phoneError === '') {
            dispatch(patchUser(dataToSend))
        }
    };

    return (
            <Box
                className={classesCard.editorCard}

            >
                <Typography variant="h5" gutterBottom sx={{marginTop: '0px'}}>
                    {t("Edit contacts")}
                </Typography>

                <TextField
                    fullWidth
                    sx={{marginTop: 2}}
                    variant='filled'
                    id='companyContactsEmail'
                    label={t('email')}
                    name='companyContactsEmail'
                    type='email'
                    onChange={(e) => handleFieldChange('email', e.target.value)}
                    value={formData.email || ''}
                />
                <TextField
                    fullWidth
                    sx={{marginTop: 2}}
                    variant='filled'
                    id='companyContactsPhone'
                    label={t('phone')}
                    name='companyContactsPhone'
                    type='tel'
                    onChange={(e) => handleFieldChange('phone', e.target.value)}
                    error={!!phoneError}
                    helperText={phoneError}
                    value={formData.phone || ''}
                />
                {Object.keys(companyInfo.required_vacancy_langs).map((key) => (
                    <FormControl fullWidth key={key} sx={{marginTop: 2}}>
                        <InputLabel id={`${key}-label`}>{t('Required language')}: {key.toLocaleUpperCase()}</InputLabel>
                        <Select
                            variant='filled'
                            labelId={`${key}-label`}
                            className={classesCard.editorCard__m2a_select}
                            value={formData.required_vacancy_langs[key] || ''}
                            onChange={(e) => handleLangReqChange(key, e.target.value)}
                        >
                        {reqLangOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                ))}
                <FormControl fullWidth sx={{marginTop: 2}}>
                    <InputLabel id='default-langs-label'>{t("Default Languages")}</InputLabel>
                    <Select
                        variant='filled'
                        className={classesCard.editorCard__m2a_select}
                        labelId='default-langs-label'
                        multiple
                        value={formData.vacancy_default_langs || []}
                        onChange={handleDefaultLangsChange}
                    >
                        {['am', 'en', 'ru'].map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button
                    fullWidth
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleFormSubmit}
                >
                    {t('save')}
                </Button>
            </Box>
    );
};

export default Contacts;
