import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { MAX_ACTIVE_VACANCIES } from '../../../../utils/const';
import { fetchVacancies } from '../../../../store/action-creators/vacancy';

import VacancyCard from './vacancy-card';
import M2ALoader from '../../../templateParts/loader'
import VacancyCounter from '../templates/vacancy-counter';
// import NewVacancyForm from './new-vacancy-form';
import VacancyForm from '../vacancy-form/vacancy-form';
import NoCompanySection from '../templates/no-company-section';

const selectVacancies = store => store.vacancy.vacancies
const selectIsLoading = store => store.vacancy.isLoadding

const MyVacancies = () => {
    const vacancies = useSelector(selectVacancies)
    const isLoading = useSelector(selectIsLoading)
    const dispatch = useDispatch();
    const [isMaxVacanciesAlertOpened, setIsVacanciesAlertOpened] = useState(false);
    const [isFormOpened, setIsFormOpened] = useState(false)
    const [currentVacancyId, setCurrentVacancyId] = useState('')
    const [activeVacancies, setActiveVacancies] = useState([])

    const editorTypes = {
		edit: 'edit',
		copy: 'copy',
	}

    const [editorType, setEditorType] = useState(editorTypes.edit)

    const fn = () => {
        dispatch(fetchVacancies())
        setActiveVacancies(vacancies[0].jobs)
    }

    useEffect(() =>{
        dispatch(fetchVacancies())
    }, [dispatch])

    useEffect(() => {
        if (vacancies[0]) {
            setActiveVacancies(vacancies[0].jobs)
        }
    },[vacancies])

    if (isLoading) {
        return(<M2ALoader/>)
    }

    const handleVacancyCopy = (id) => {
        if (activeVacancies.length === MAX_ACTIVE_VACANCIES) {
            setIsVacanciesAlertOpened(true);
        } else {
            setCurrentVacancyId(id)
            setEditorType(editorTypes.copy)
            setIsFormOpened(true)
        }
    };

    const handleVacancyEdit = (id) => {
        setCurrentVacancyId(id)
        setEditorType(editorTypes.edit)
        setIsFormOpened(true)
    };


    // const fn = () => {
    //     dispatch(fetchVacancies())
    //     setActiveVacancies(vacancies[0].jobs.filter(vacancy => vacancy.status === 'publish'))
    // }

    return (
        <>
        {
            !isFormOpened ? (
            <>
                <VacancyCounter
                    activeVacancies={activeVacancies}
                    onAlertOpen={isMaxVacanciesAlertOpened}
                    onAlertClose={() => setIsVacanciesAlertOpened(false)}
                />
                <Grid container  sx={{  marginTop: '20px' }}>
                    {
                        !vacancies.length
                        ? <NoCompanySection />
                        : (vacancies[0].jobs.map((item) => (
                            <VacancyCard
                                key={item.id}
                                vacancy={item}
                                onCopy={handleVacancyCopy}
                                onEdit={handleVacancyEdit}
                                updateCb={fn}
                            />
                        )))
                    }
                </Grid>
            </>
            ) : (
                <VacancyForm
                    vacancy={currentVacancyId}
                    editorType={editorType}
                    onClose={(() => setIsFormOpened(false))}
                    updateCb={() => dispatch(fetchVacancies())}
                />
            )
        }
        </>
    );
}

export default MyVacancies;