import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, FormLabel, FormControlLabel, Typography, Modal, Switch, FormGroup, Checkbox, Grid } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

import { fetchRegions, fetchSpecs, patchVacancy, postVacancy } from '../../../../store/action-creators/vacancy';
import { fetchCompanies } from '../../../../store/action-creators/company';
import classesCard from "../../../../css/Card.module.scss";

import { CURRENCY_OPTIONS, EMPLOYMENT_OPTIONS, EXPERIENCE_OPTIONS, LANGUAGE_OPTIONS, REMOTE_WORK_OPTIONS, SALARY_OPTIONS, TYPE_OPTIONS, WORK_TYPE_OPTIONS } from '../../../../utils/const'
import { REQUIRED_FIELDS } from './const';
import { getDataToSend, handleFieldChange } from './utils';

const selectSpecs = store => store.vacancy.specs
const selectCompanies = store => store.company.companies
const selectRegions = store => store.vacancy.regions

const VacancyForm = ({vacancy, editorType, onClose, updateCb}) => {
    const { t } = useTranslation();
    const specs = useSelector(selectSpecs)
    const dispatch = useDispatch()
    const companies = useSelector(selectCompanies);
    const regions = useSelector(selectRegions)

    const [open, setOpen] = useState(false);
    const [selectedJobLanguageRu, setSelectedJobLanguageRu] = useState(true);
    const [selectedJobLanguageAm, setSelectedJobLanguageAm] = useState(false);
    const [selectedJobLanguageEn, setSelectedJobLanguageEn] = useState(false);
    // const [errors, setErrors] = useState(false)
    const [modalLang, setModalLang] = useState('');
    const [titleError, setTitleError] = useState(false)
    const [fieldErrors, setFieldErrors] = useState([])
    const [formData, setFormData] = useState({
        title : vacancy?.title,
        job_responsibilities: vacancy?.meta?.job_responsibilities,
        required_qualifications: vacancy?.meta?.required_qualifications,
        specializaciya: vacancy?.meta?.specializaciya,
        english: vacancy?.meta?.english,
        armenian: vacancy?.meta?.armenian,
        russian: vacancy?.meta?.russian,
        type: vacancy?.meta?.type,
        experience: vacancy?.meta?.experience,
        salary_type: vacancy?.meta?.salary_type,
        min_salary: vacancy?.meta?.min_salary,
        max_salary: vacancy?.meta?.max_salary,
        currency: vacancy?.meta?.currency,
        employment_type: vacancy?.meta?.employment_type,
        work_type: vacancy?.meta?.work_type,
        remove_work_is_possible: vacancy?.meta?.remove_work_is_possible,
        job_status: vacancy?.meta?.job_status,
        job_city: vacancy?.meta?.job_city,
        other_region: vacancy?.meta?.other_region,
        title_am: vacancy?.meta?.title_am,
        title_en: vacancy?.meta?.title_en,
        description: vacancy?.meta?.description,
        description_am: vacancy?.meta?.description_am,
        description_en: vacancy?.meta?.description_en,
        peculiarities_for_disabled_applicants :  vacancy?.meta?.peculiarities_for_disabled_applicants === '1' ? true : false,
        peculiarities_category_b_license :  vacancy?.meta?.peculiarities_category_b_license === '1' ? true : false,
        peculiarities_category_y_license :  vacancy?.meta?.peculiarities_category_y_license === '1' ? true : false,
        peculiarities_need_car :  vacancy?.meta?.peculiarities_need_car === '1' ? true : false,
        peculiarities_for_persons_from_14_yo :  vacancy?.meta?.peculiarities_for_persons_from_14_yo === '1' ? true : false,
        peculiarities_accommodation :  vacancy?.meta?.peculiarities_accommodation === '1' ? true : false,
        peculiarities_powered :  vacancy?.meta?.peculiarities_powered === '1' ? true : false,
        peculiarities_need_a_workpermit :  vacancy?.meta?.peculiarities_need_a_workpermit === '1' ? true : false,
        peculiarities_piecework :  vacancy?.meta?.peculiarities_piecework === '1' ? true : false,
        peculiarities_can_go_out_tomorrow :  vacancy?.meta?.peculiarities_can_go_out_tomorrow === '1' ? true : false,
        peculiarities_relocation_package :  vacancy?.meta?.peculiarities_relocation_package === '1' ? true : false,
        peculiarities_possible_without_experience :  vacancy?.meta?.peculiarities_possible_without_experience === '1' ? true : false,
        m2a_profadditional_type_company: vacancy?.meta?.m2a_profadditional_type_company,
    });
    const [selectedSpecialization, selectSpecialization] = useState(formData.specializaciya);
    const [selectedJobCity, selectJobCity] = useState(formData.job_city)
    const [preview, setPreviw] = useState(false)

    useEffect(() =>{
        dispatch(fetchSpecs())
        dispatch(fetchCompanies())
        dispatch(fetchRegions())
    }, [dispatch])

    const handleLang = (lang, value) => {
        if(lang === 'Ru'){
            setSelectedJobLanguageRu(value)
            if(!value){
                handleFieldChange('title', '', fieldErrors, setFieldErrors, setFormData)
            }
        }
        if(lang === 'Am'){
            setSelectedJobLanguageAm(value)
            if(!value){
                handleFieldChange('title_am', '', fieldErrors, setFieldErrors, setFormData)
            }
        }
        if(lang === 'En'){
            setSelectedJobLanguageEn(value)
            if(!value){
                handleFieldChange('title_en', '', fieldErrors, setFieldErrors, setFormData)
            }
        }
        if(!value){
            setOpen(true)
        }
        setModalLang(lang)
    };

    const handleClose = () => {
        handleLang(modalLang, true)
        setOpen(false);
        setModalLang('')
    }

    const handleOnlyClose = () => {
        if (!selectedJobLanguageRu && !selectedJobLanguageEn && !selectedJobLanguageAm){
            // setDisabledBtn(true)
            setOpen(false);
            setSelectedJobLanguageRu(true)
            toast.error('At least one language must be selected')
        } else {
            setOpen(false);
            setModalLang('')
        }
    }

    const handlePreview = () => {
        const dataToSend = getDataToSend(formData, companies, selectedSpecialization, selectedJobCity)

        setTitleError(false)

        let errorsFields = []
        let errorsTr = false
        // setErrors(false)

        Object.keys(formData).forEach((key) => {
            if (REQUIRED_FIELDS.includes(key)) {
                if (dataToSend[key] === undefined || dataToSend[key] ==='' ){
                    if (
                        key === 'title'
                        || key === 'description'
                        || key === 'title_am'
                        || key === 'description_am'
                        || key === 'title_en'
                        || key === 'description_en'
                    ) {
                        if (
                            (key === 'title'|| key === 'description')
                            && selectedJobLanguageRu
                        ) {
                            errorsFields[key] = true
                            setFieldErrors(errorsFields)
                            // setErrors(true)
                            errorsTr = true
                        }
                        if (
                            (key === 'title_am' || key === 'description_am')
                            && selectedJobLanguageAm
                        ) {
                            errorsFields[key] = true
                            setFieldErrors(errorsFields)
                            errorsTr = true
                        }
                        if (
                            (key === 'title_en' || key ==='description_en')
                            && selectedJobLanguageEn
                        ) {
                            errorsFields[key] = true
                            setFieldErrors(errorsFields)
                            // setErrors(true)
                            errorsTr = true
                        }
                    } else {
                        errorsFields[key] = true
                        setFieldErrors(errorsFields)
                        // setErrors(true)
                        errorsTr = true
                    }
                } else {
                    errorsFields[key] = false
                    setFieldErrors(errorsFields)
                }

                if(key === 'specializaciya'){
                    if(selectedSpecialization === '' ||  selectedSpecialization === undefined){
                        errorsFields['specializaciya'] = true
                        setFieldErrors(errorsFields)

                        // setErrors(true)
                        errorsTr = true
                    }
                }

                if(key === 'job_city'){

                    if(selectedJobCity === '' ||  selectedJobCity === undefined){
                        errorsFields['regions'] = true
                        setFieldErrors(errorsFields)

                        // setErrors(true)
                        errorsTr = true
                    }
                }
            }
        })

        if(errorsTr){ toast.error('Please fill all required fields') }else{
            setPreviw(true)
        }
    }

    const handleSubmitForm = () => {

        const dataToSend = getDataToSend(formData, companies, selectedSpecialization, selectedJobCity)
        // const dataToSendFilter = dataToSend.filter(Boolean);
        dataToSend.post_status = 'publish';
        Object.keys(dataToSend).forEach(key => {
            if (dataToSend[key] === undefined) {
                delete dataToSend[key];
            }
        });

        if (editorType === 'edit' ) {
            dispatch(patchVacancy(vacancy.id, dataToSend))
        } else if (editorType === 'copy') {
            dispatch(postVacancy(dataToSend))
        } else {
        }

        updateCb()
        onClose()
    };

    const handleSubmitFormDraft = () => {

        const dataToSend = getDataToSend(formData, companies, selectedSpecialization, selectedJobCity)
        // const dataToSendFilter = dataToSend.filter(Boolean);
        dataToSend.post_status = 'draft';
        Object.keys(dataToSend).forEach(key => {
            if (dataToSend[key] === undefined) {
                delete dataToSend[key];
            }
        });

        if (editorType === 'edit' ) {
            dispatch(patchVacancy(vacancy.id, dataToSend))
        } else if (editorType === 'copy') {
            dispatch(postVacancy(dataToSend))
        } else {
        }

        // updateCb()
        // onClose()
    };




    const list = []
    for (let i = 0; i < specs.length; i++) {
      list.push(<ListSubheader key={`spec-${i}`}>{specs[i].spec.name}</ListSubheader>);
      specs[i].childrens.map((subOption, j) =>
        list.push(
          <MenuItem key={`spec-${i}-child-${j}`} value={subOption.term_id}>
            {subOption.name}
          </MenuItem>
        )
      );
    }
    
    const listCity = []
    for (let i = 0; i < regions.length; i++) {
      listCity.push(
        <ListSubheader key={`region-${i}`}>{regions[i].country.name}</ListSubheader>
      );
      regions[i].childrens.map((subOption, j) =>
        listCity.push(
          <MenuItem key={`region-${i}-child-${j}`} value={subOption.term_id}>
            {subOption.name}
          </MenuItem>
        )
      );
    }

    return(
        <>
        {!preview 
        ? (<Box className={classesCard.editorCard}>

            <Typography variant="h5" gutterBottom sx={{marginTop: '0px'}}>
                { editorType === 'edit' ?  t("Edit vacancy") + ' ' + formData.title || '' : '' }
                { editorType === 'copy' ?  t("Copy vacancy") + ' ' + formData.title || '' : '' }
            </Typography>

            <FormGroup>
                <FormLabel sx={{marginTop: '20px'}}>{t('vacancyLanguage')}</FormLabel>
                <Box>
                    <FormControlLabel key={'ru'} control={
                        <Switch
                            checked={selectedJobLanguageRu}
                            onChange={(e => handleLang('Ru', e.target.checked))}/>
                    } label="Русский"/>
                    <FormControlLabel key={'en'} control={
                        <Switch
                            value="EN"
                            onChange={(e => handleLang('En', e.target.checked))}
                            checked={selectedJobLanguageEn}
                        />} label="English"/>
                    <FormControlLabel key={'am'} control={
                        <Switch
                            value="AM"
                            onChange={(e => handleLang('Am', e.target.checked))}
                            checked={selectedJobLanguageAm}
                        />} label="հայերեն"/>
                </Box>
            </FormGroup>

            {selectedJobLanguageRu && (
                <>
                    <TextField
                        key={'titleRu'}
                        fullWidth
                        sx={{marginTop: 2}}
                        variant='filled'
                        id='jobFormTitleRu'
                        label={t('title') + ' RU'}
                        required
                        error={fieldErrors.title}
                        name='jobFormTitleRu'
                        type='text'
                        onChange={(e) => handleFieldChange('title', e.target.value, fieldErrors, setFieldErrors, setFormData)}
                        value={formData.title || ''}
                    />
                    <FormControl  key={'descriptionRu'} fullWidth sx={{marginTop: 2}} required>
                        <InputLabel
                            sx={{position: 'inherit', marginBottom: '30px', left: '0px', marginLeft: '-10px'}}>{t('description')} RU</InputLabel>
                        <ReactQuill

                            id="descriptionRu"
                            value={formData.description || ''}
                            onChange={(value) => handleFieldChange('description', value, fieldErrors, setFieldErrors, setFormData)}
                        />
                    </FormControl>
                </>
            )}

            {selectedJobLanguageEn && (
                <>
                    <TextField
                        key={'titleEn'}
                        fullWidth
                        sx={{marginTop: 2}}
                        variant='filled'
                        id='jobFormTitleEn'
                        label={t('title') + ' EN'}
                        required
                        error={fieldErrors.title_en}
                        name='jobFormTitleEn'
                        type='text'
                        onChange={(e) => handleFieldChange('title_en', e.target.value, fieldErrors, setFieldErrors, setFormData)}
                        value={formData.title_en || ''}
                    />
                    <FormControl key={'descriptionEn'} fullWidth sx={{marginTop: 2}} required>
                        <InputLabel
                            sx={{position: 'inherit', marginBottom: '30px', left: '0px', marginLeft: '-10px'}}>{t('description')} EN</InputLabel>
                        <ReactQuill
                            id="descriptionEn"
                            value={formData.description_en || ''}
                            onChange={(value) => handleFieldChange('description_en', value, fieldErrors, setFieldErrors, setFormData)}
                        />
                    </FormControl>
                </>

            )}

            {selectedJobLanguageAm && (
                <>
                    <TextField
                        key={'titleAm'}
                        fullWidth
                        sx={{marginTop: 2}}
                        variant='filled'
                        id='jobFormTitleAm'
                        required
                        error={fieldErrors.title_am}
                        label={t('title') + ' AM'}
                        name='jobFormTitleAm'
                        type='text'
                        onChange={(e) => handleFieldChange('title_am', e.target.value, fieldErrors, setFieldErrors, setFormData)}
                        value={formData.title_am || ''}
                    />
                    <FormControl  key={'descriptionAm'} fullWidth sx={{marginTop: 2}} required>
                        <InputLabel
                            sx={{position: 'inherit', marginBottom: '30px', left: '0px', marginLeft: '-10px'}}>{t('description')} AM</InputLabel>
                        <ReactQuill
                            id="descriptionAm"
                            value={formData.description_am || ''}
                            onChange={(value) => handleFieldChange('description_am', value, fieldErrors, setFieldErrors, setFormData)}
                        />
                    </FormControl>
                </>
            )}

            {Object.keys(formData).map((item) => {
                if (
                    item === 'job_responsibilities'
                    || item === 'required_qualifications'
                    || (item === 'other_region' && selectedJobCity === 'other')
                    || item === 'min_salary'
                    || item === 'max_salary'
                ) {
                    return (
                        <TextField
                            key={item}
                            fullWidth
                            sx={{marginTop: 2}}
                            variant='filled'
                            id={item}
                            label={t(item)}
                            name={item}
                            type='text'
                            onChange={(e) => handleFieldChange(item, e.target.value, fieldErrors, setFieldErrors, setFormData)}
                            value={formData[item] || ''}
                        />
                    )
                } else if (
                    item === 'specializaciya'
                    || item === 'job_city'
                ) {
                    const selectProps = {
                        specializaciya: {
                            value: selectedSpecialization,
                            cb: selectSpecialization,
                            options: list,
                        },
                        job_city: {
                            value: selectedJobCity,
                            cb: selectJobCity,
                            options: listCity,
                        },
                    }
                    
                    return (
                        <FormControl key={item} fullWidth sx={{marginTop: 2}}>
                            <InputLabel id={item}>
                                {t(item)}
                            </InputLabel>
                            <Select
                                labelId={item}
                                variant="filled"
                                value={selectProps[item].value || ''}
                                className={classesCard.editorCard__m2a_select}
                                onChange={(e) => selectProps[item].cb(e.target.value, fieldErrors, setFieldErrors, setFormData)}
                            >
                                {item === 'job_city' && <MenuItem id={'noCity'} value={'Не имеет значения'}>{t('Не имеет значения')}</MenuItem>}
                                {item === 'job_city' && <MenuItem id={'other'} value={'other'}>{t('Другое')}</MenuItem>}
                                {selectProps[item].options}
                            </Select>
                        </FormControl>
                    )
                } else if (
                    item === 'english'
                    || item === 'armenian'
                    || item === 'russian'
                ) {
                    return (
                        <FormControl  key={item} fullWidth sx={{marginTop: 2}}>
                            <InputLabel id={`${item}ReqLabel`}>{t(`${item}Level`)}</InputLabel>
                            <Select
                                variant="filled"
                                labelId={`${item}ReqLabel`}
                                value={formData[item] || ''}
                                className={classesCard.editorCard__m2a_select}
                                onChange={(e) => handleFieldChange(item, e.target.value, fieldErrors, setFieldErrors, setFormData)}
                            >
                                {LANGUAGE_OPTIONS.map((option) => (
                                    <MenuItem key={`${item}Req${option}`} value={option}>
                                        {t(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                } else if (
                    item === 'type'
                    || item === 'experience'
                    || item === 'currency'
                    || item === 'employment_type'
                    || item === 'salary_type'
                    || item === 'work_type'
                    || item === 'remove_work_is_possible'
                ) {
                    const options = {
                        'type': TYPE_OPTIONS,
                        'experience': EXPERIENCE_OPTIONS,
                        'currency': CURRENCY_OPTIONS,
                        'employment_type': EMPLOYMENT_OPTIONS,
                        'salary_type': SALARY_OPTIONS,
                        'work_type': WORK_TYPE_OPTIONS,
                        'remove_work_is_possible': REMOTE_WORK_OPTIONS,
                    }
                    return (
                        <FormControl key={item} fullWidth sx={{marginTop: 2}}>
                            <InputLabel id={`${item}Label`}>{t(item)}</InputLabel>
                            <Select
                                variant="filled"
                                labelId={`${item}Label`}
                                value={formData[item] || ''}
                                className={classesCard.editorCard__m2a_select}
                                onChange={(e) => handleFieldChange(item, e.target.value, fieldErrors, setFieldErrors, setFormData)}
                            >
                                {options[item].map((option) => (
                                    <MenuItem key={`${item}${option}`} value={option}>
                                        {t(option)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                } else {
                    return null
                }
            })}

            <InputLabel sx={{marginTop: '20px'}} id={'m2a_profadditional_fields_vacancyLabel'}>{t('Peculiarities')}</InputLabel>
            {Object.keys(formData).map((item) => {
                if (
                    item === 'peculiarities_for_disabled_applicants'
                    || item === 'peculiarities_category_b_license'
                    || item === 'peculiarities_category_y_license'
                    || item === 'peculiarities_need_car'
                    || item === 'peculiarities_for_persons_from_14_yo'
                    || item === 'peculiarities_accommodation'
                    || item === 'peculiarities_powered'
                    || item === 'peculiarities_need_a_workpermit'
                    || item === 'peculiarities_piecework'
                    || item === 'peculiarities_can_go_out_tomorrow'
                    || item === 'peculiarities_relocation_package'
                    || item === 'peculiarities_possible_without_experience'
                ) {
                    return (  
                        <FormControlLabel key={item} control={
                            <Checkbox
                                key={`${item}-checkbox`}
                                checked={formData[item]}
                                onChange={(e) => handleFieldChange(item, e.target.checked, fieldErrors, setFieldErrors, setFormData)}
                            />} label={t(item)}
                        />
                    )
                } else {
                    return null
                }
            })}
       
            <FormControl key={'profadditional_type'} fullWidth sx={{marginTop: 2}} >
                <InputLabel id='m2a_profadditional_type_companyLabel'>{t("Type of Employer")}</InputLabel>
                <Select
                    variant="filled"
                    labelId={'m2a_profadditional_type_companyLabel'}
                    value={formData.m2a_profadditional_type_company || ''}

                    defaultValue={'direct_employer'}
                    className={classesCard.editorCard__m2a_select}
                    onChange={(e) => handleFieldChange('m2a_profadditional_type_company', e.target.value, fieldErrors, setFieldErrors, setFormData)}
                >
                    <MenuItem key={`private_person`} value='private_person'>
                        {t('Private person')}
                    </MenuItem>
                    <MenuItem key={`agency`} value='agency'>
                        {t('Agency')}
                    </MenuItem>
                    <MenuItem key={`direct_employer`} value='direct_employer'>
                        {t('Direct employer')}
                    </MenuItem>
                </Select>
            </FormControl>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classesCard.modal_sure}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">

                        {t('Are you sure about disabling the language')} {modalLang} ? <br/> {t('\n' +
                        'Information will not be saved')}
                    </Typography>
                    <Box sx={{display: 'flex', width: '100%', gap: '20px'}}>
                        <Button

                            variant='contained'
                            sx={{mt: 3, mb: 2}}
                            // disabled={disabledBtn}
                            onClick={handleClose}
                        >
                            {t('yes')}
                        </Button>
                        <Button

                            variant='contained'
                            sx={{mt: 3, mb: 2}}
                            // disabled={disabledBtn}
                            onClick={handleOnlyClose}
                        >
                            {t('no')}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Button
                fullWidth
                variant='contained'
                sx={{mt: 3, mb: 2}}
                // onClick={handleSubmitForm}
                onClick={handlePreview}
            >
                {t('Save')}
            </Button>

            <Button
                fullWidth
                variant='contained'
                sx={{mt: 3, mb: 2}}
                onClick={onClose}
            >
                {t('Cancel')}
            </Button>
        </Box>) 
        : (<>
            <Grid container spacing={2}>
                <Grid item md={3}>
                    <Button
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        // disabled={disabledBtn}
                        onClick={() => setPreviw(false)}
                    >
                        {t('Back to Edit')}
                    </Button>
                </Grid>
                <Grid item md={3}>
                    <Button
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => handleSubmitForm()}
                    >
                        {t('Save')}
                    </Button>
                </Grid>
                <Grid item md={3}>
                    <Button
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => handleSubmitFormDraft()}
                    >
                        {t('To Draft')}
                    </Button>
                </Grid>
                <Grid item md={3}>
                    <Button
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        // disabled={disabledBtn}
                        onClick={onClose}
                    >
                        {t('Delete')}
                    </Button>
                </Grid>
            </Grid>
            <Typography
                sx={{
                    color: '#242429',
                    fontSize: '36px',
                    textAlign: 'center',
                    lineHeight: 1.25,
                    fontWeight: 600,
                    marginTop: '20px',
                    marginBottom: '40px'
                }}
            >
                {formData.title}
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={7}>
                    <Box sx={{ marginBottom: '25px', background: '#fff', padding: '20px',
                        boxShadow: '0 1px 4px #00000012', borderRadius: '5px'}}>
                        <Typography sx={{fontWeight: '600', color: '#242429', paddingBottom: '0',
                            marginBottom: '0', marginTop: '0', letterSpacing: '0', fontSize: '14px',
                            lineHeight: '20px' }}>{t('description')}</Typography>
                        <Typography sx={{paddingTop: '15px', width: '100%', fontSize: '14px', lineHeight: '22px', wordBreak: 'break-all'}}> <div dangerouslySetInnerHTML={{ __html:formData.description}} /> </Typography>
                    </Box>
                </Grid>
                <Grid item md={5}>
                    <Button
                        variant='contained'
                        sx={{ width: '100%', marginBottom: '25px', color: 'white' }}
                    >
                        {t('Respond')}
                    </Button>
                    <Box sx={{ marginBottom: '25px', background: '#fff', padding: '20px',
                        boxShadow: '0 1px 4px #00000012', borderRadius: '5px'}}>
                        <Typography sx={{fontWeight: '600', color: '#242429', paddingBottom: '0',
                            marginBottom: '0', marginTop: '0', letterSpacing: '0', fontSize: '14px',
                            lineHeight: '20px' }}>{t("Required experience")}</Typography>
                        <Typography sx={{paddingTop: '15px', width: '100%', fontSize: '14px', lineHeight: '22px'}}>{formData.experience}</Typography>
                    </Box>
                    <Box sx={{ marginBottom: '25px', background: '#fff', padding: '20px',
                        boxShadow: '0 1px 4px #00000012', borderRadius: '5px'}}>
                        <Typography sx={{fontWeight: '600', color: '#242429', paddingBottom: '0',
                            marginBottom: '0', marginTop: '0', letterSpacing: '0', fontSize: '14px',
                            lineHeight: '20px' }}>{t('vacancyLanguage')}</Typography>
                        <Typography sx={{paddingTop: '15px', width: '100%', fontSize: '14px', lineHeight: '22px'}}>
                            Russian : {formData.russian} <br/>
                            English : {formData.english} <br/>
                            Armenian : {formData.armenian}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>)
        }
        </>
    )
}

export default VacancyForm;