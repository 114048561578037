import { Grid, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NewCategoryForm = ({ onChange, onSubmit }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <TextField
                    required
                    fullWidth
                    margin='normal'
                    variant='filled'
                    id='newCompanyCategory'
                    label={t('newCategoryTitle')}
                    name='newCompanyCategory'
                    type='text'
                    onChange={(e) => onChange(e.target.value)}
                />
            </Grid>
            <Grid item xs={4}>
                <Button
                    fullWidth
                    variant='contained'
                    sx={{ mt: 3, mb: 2 }}
                    onClick={(value) => onSubmit(value)}
                >
                    {t('add')}
                </Button>
            </Grid>
        </Grid>
    )
}

export default NewCategoryForm;
