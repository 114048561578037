import {useEffect, useState} from 'react';
import { Container, Box, Tabs, Tab } from '@mui/material';
import { Outlet, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { CAREER_PATH, CAREER_SECTION_ENDPOINT } from './utils/const';
import {useDispatch, useSelector} from "react-redux";
import {fetchCompanies} from "../../../store/action-creators/company";
import M2ALoader from "../../templateParts/loader";

const getCurrentTab = (path) => {
    switch (path) {
        case CAREER_PATH.NEW_VACANCY:
            return 0
        case CAREER_PATH.MY_VACANCIES:
            return 1
        case CAREER_PATH.CONTACTS:
            return 2
        case CAREER_PATH.APPLICANTS:
            return 3
        default:
            return 0
    }
}
const selectCompanies = state => state.company.companies;
const selectIsCompaniesLoading = state => state.company.isCompaniesLoading;
const CareerSection = () => {
    const dispatch = useDispatch()
    const [currentTab, setCurrentTab] = useState(getCurrentTab(CAREER_SECTION_ENDPOINT))
    const { t } = useTranslation()
    const companies = useSelector(selectCompanies)
    const isCompaniesLoading = useSelector(selectIsCompaniesLoading)
    const handleChange = (e, newCurrentTab) => {
      setCurrentTab(newCurrentTab)
    }

    useEffect(()=>{
        dispatch(fetchCompanies())
    }, [dispatch])



        if(companies.length == 0){
            window.location.replace('/profile/catalog')
            return (
                <>
                </>
            )
        }else{
            return (
                <Container sx={{ py: 8 }} maxWidth='lg'>
                    <Box>
                        <Tabs
                            variant="fullWidth"
                            value={currentTab}
                            onChange={handleChange}
                            aria-label='career-tabs'
                            sx={{ borderRight: 1, borderColor: 'divider', background: 'white' }}
                        >
                            <Tab label={t('newVacancy')} to={CAREER_PATH.NEW_VACANCY} component={Link} />
                            <Tab label={t('myVacancies')} to={CAREER_PATH.MY_VACANCIES} component={Link} />
                            <Tab label={t('contacs')} to={CAREER_PATH.CONTACTS} component={Link} />
                            <Tab label={t('applicants')} to={CAREER_PATH.APPLICANTS} component={Link} />
                        </Tabs>
                        <Outlet />
                    </Box>
                </Container>
            );
        }



};

export default CareerSection;