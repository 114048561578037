export const FETCH_VACANCIES = 'FETCH_VACANCIES'
export const FETCH_VACANCIES_SUCCESS = 'FETCH_VACANCIES_SUCCESS'
export const FETCH_VACANCIES_ERROR = 'FETCH_VACANCIES_ERROR'

export const FETCH_VACANCY = 'FETCH_VACANCY'
export const FETCH_VACANCY_SUCCESS = 'FETCH_VACANCY_SUCCESS'
export const FETCH_VACANCY_ERROR = 'FETCH_VACANCY_ERROR'

export const DELETE_VACANCY = 'DELETE_VACANCY'
export const DELETE_VACANCY_SUCCESS = 'DELETE_VACANCY_SUCCESS'
export const DELETE_VACANCY_ERROR = 'DELETE_VACANCY_ERROR'

export const FETCH_SPECS = 'FETCH_SPECS'
export const FETCH_SPECS_SUCCESS = 'FETCH_SPECS_SUCCESS'
export const FETCH_SPECS_ERROR = 'FETCH_SPECS_SUCCESS'


export const FETCH_REGIONS = 'FETCH_REGIONS'
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS'
export const FETCH_REGIONS_ERROR = 'FETCH_REGIONS_ERROR'

export const PATCH_VACANCY = 'PATCH_VACANCY'
export const PATCH_VACANCY_SUCCESS = 'PATCH_VACANCY_SUCCESS'
export const PATCH_VACANCY_ERROR = 'PATCH_VACANCY_ERROR'

export const POST_VACANCY = 'POST_VACANCY'
export const POST_VACANCY_SUCCESS = 'POST_VACANCY_SUCCESS'
export const POST_VACANCY_ERROR = 'POST_VACANCY_ERROR'

const initialState = {
    vacancies: {},
    vacancy: {},
    specs: [],
    regions: []
}

export const vacancyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VACANCIES:
            return {...state, isLoading: false}
        case FETCH_VACANCIES_SUCCESS:
            return {...state, isLoading: false, vacancies: action.payload}
        case FETCH_VACANCIES_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case FETCH_VACANCY:
            return {...state, isLoading: false}
        case FETCH_VACANCY_SUCCESS:
            return {...state, isLoading: false, vacancy: action.payload}
        case FETCH_VACANCY_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case FETCH_SPECS:
            return {...state, isLoading: false}
        case FETCH_SPECS_SUCCESS:
            return {...state, isLoading: false, specs: action.payload}
        case FETCH_SPECS_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case FETCH_REGIONS:
            return {...state, isLoading: false}
        case FETCH_REGIONS_SUCCESS:
            return {...state, isLoading: false, regions: action.payload}
        case FETCH_REGIONS_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case DELETE_VACANCY:
            return {...state, isLoading: false}
        case DELETE_VACANCY_SUCCESS:
            return {...state, isLoading: false}
        case DELETE_VACANCY_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case PATCH_VACANCY:
            return {...state, isLoading: false,}
        case PATCH_VACANCY_SUCCESS:
            return {...state, isLoading: false, fields: action.payload}
        case PATCH_VACANCY_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case POST_VACANCY:
            return {...state, isLoading: false}
        case POST_VACANCY_SUCCESS:
            return {...state, isLoading: false}
        case POST_VACANCY_ERROR:
            return {...state, isLoading: false, error: action.payload}
        default:
            return state
    }
}
