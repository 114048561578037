import {useEffect, useState} from 'react';
import {
    Card,
    CardContent,
    Grid,
    Button,
    Typography,
    Box,
    Chip, IconButton, Tooltip
} from '@mui/material';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import MailIcon from '@mui/icons-material/Mail';
import { RESPONSE_REPLY_TYPE } from '../../../../utils/const';
import ApplicantModalReply from './applicant-modal-reply';
import ApplicantModalInfo from './applicant-modal-info';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import classes from '../../../../css/Applicants.module.scss'
import {fetchCompanies} from "../../../../store/action-creators/company";
import {fetchRegions, fetchSpecs} from "../../../../store/action-creators/vacancy";
import {useTranslation} from "react-i18next";

const ApplicantCard = ({response , contacts, updateCb}) => {
    const [isModalIfnoOpened, setIsModaInfolOpened] = useState(false);
    const [isModalAcceptReplyOpened, setIsModalAcceptReplyOpened] = useState(false);
    const [isModalRejectReplyOpened, setIsModalRejectReplyOpened] = useState(false);

    const [backgroundColor, setBackgroundColor] = useState('')
    const { t } = useTranslation();

    useEffect(() =>{


        if(response.applicant_status === 'rejected'){
            setBackgroundColor('#ff5a5a')
        }

        if(response.applicant_status === 'pending' || response.applicant_status === 'thinking'){
            setBackgroundColor('#00b9be')
        }

        if(response.applicant_status === 'approved'){
            setBackgroundColor('#66bb6a')
        }

    }, [response.applicant_status])

    return (
        <>
            <Card key={response.ID} sx={{margin: 4,}} >
                <CardContent>
                    <Grid container sx={{ position: 'relative'}}>
                        <Grid item md={8} sx={{ cursor: 'pointer'}} onClick={() => (setIsModaInfolOpened(true))}>


                            <Grid item xs={12} >
                                { response.reusume.resume_download != '' ? (
                                <Typography variant="subtitle1" gutterBottom>
                                    <Typography variant='subtitle2'>{t("Name")}</Typography>
                                    {response.reusume.name}
                                </Typography>
                                    ) : (
                                    <Typography variant="subtitle1" gutterBottom>
                                        <Typography variant='subtitle2'>{t("Name")}</Typography>
                                        {response.reusume.name}
                                    </Typography>
                                    )
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption" gutterBottom>
                                    {response.reusume.phone} {response.reusume.email}
                                </Typography>
                                {response.id}
                            </Grid>
                        </Grid>

                        <Box sx={{ position: 'absolute', top: '10px', right: '10px'}} >


                            <Chip sx={{ backgroundColor: {backgroundColor} }}  label={t(response.applicant_status)} color="success" />
                        </Box>


                        <Grid item xs={12} justifyContent="flex-end" container>

                            {response.applicant_status == 'pending' ? (
                                <>
                            <Button
                                sx={{margin: 1, color: 'white'}}
                                variant="contained"
                                onClick={() => setIsModalRejectReplyOpened(true)}
                            >
                                {t("Reject")}

                            </Button>
                            <Button
                                sx={{margin: 1, color: 'white'}}
                                variant="contained"
                                onClick={() => setIsModalAcceptReplyOpened(true)}
                            >
                                {t("Accept")}

                            </Button>
                                </>
                                ) :

                                ''


                            }
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>

            <ApplicantModalInfo
                response={response}
                onOpen={isModalIfnoOpened}
                onClose={() => setIsModaInfolOpened(false)}
                onAccept={() => setIsModalAcceptReplyOpened(true)}
                onReject={() => setIsModalRejectReplyOpened(true)}
                // updateCb={updateCb}
            />
            <ApplicantModalReply responseId={response.ID} responseType={RESPONSE_REPLY_TYPE.ACCEPT} company={contacts} onOpen={isModalAcceptReplyOpened} onClose={() => setIsModalAcceptReplyOpened(false)} updateCb={updateCb}/>
            <ApplicantModalReply responseId={response.ID} responseType={RESPONSE_REPLY_TYPE.REJECT} company={contacts} onOpen={isModalRejectReplyOpened} onClose={() => setIsModalRejectReplyOpened(false)} updateCb={updateCb}/>
        </>
    )
}

export default ApplicantCard;