import axios from '../axios-config';
import { SERVER_URL } from '../../utils/const';
import { FETCH_APPLICANTS, FETCH_APPLICANTS_ERROR, FETCH_APPLICANTS_SUCCESS, PATCH_APPLICANT, PATCH_APPLICANT_ERROR, PATCH_APPLICANT_SUCCESS } from '../reducers/applicant-reducer';

const token = localStorage.getItem('token')

export const fetchApplicants = () => {
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_APPLICANTS})
            const response = await axios.get(
                `${SERVER_URL}/acccompany/v1/applicants`,
                {headers: {Authorization: `Bearer ${token}`}}
            )
            dispatch({
                type: FETCH_APPLICANTS_SUCCESS,
                payload: response.data,
            })
        } catch (e) {
            dispatch({
                type: FETCH_APPLICANTS_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const patchApplicant = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({type: PATCH_APPLICANT})
            const response = await axios.patch(
                `${SERVER_URL}/acccompany/v1/applicants/${id}`,
                data,
                {headers: {Authorization: `Bearer ${token}`}}
            )
            dispatch({
                type: PATCH_APPLICANT_SUCCESS
            })
        } catch (e) {
            dispatch({
                type: PATCH_APPLICANT_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}
