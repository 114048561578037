export const updateInputValues = (values, cb) => {
    if (values) {
        const formattedValues = {};

        values.forEach((item) => {
            const key = Object.keys(item)[0];
            const value = item[key].value;
            const type = item[key].type
                if (typeof(value) === 'string') {
                        formattedValues[key] = value;
                    }
                // if (type === 'term-select') {
                //     formattedValues[key] = {value: value.name, label: value.name}
                // }
                if (type === 'file' && value.length) {
                    formattedValues[key] = new File(['logo'], value[0])
                }
                if (type === 'work-hours') {
                    formattedValues[key] = value
                }
                if (type === 'links') {
                    formattedValues[key] = value
                }
                if (type === 'term-select') {
                    formattedValues[key] = value
                }
        });
        
        cb(formattedValues)
    }
}

export const handleFieldChange = (fieldName, value, cb) => {

    cb((prevFormData) => ({
        ...prevFormData,
        [fieldName]: value,
    }));
};

const formatData = (formData, company, category,) => {
    const logo = formData?.job_logo ? formData.job_logo : ''
    const cover = formData?.job_cover ? formData.job_cover : ''
    const gallery = formData?.job_gallery ? formData.job_gallery[0] : ''
    
    const updatedMeta = {
        ...formData,
        gorod: formData?.gorod?.value,
        // job_logo: logo,
        // job_cover: cover,
        // job_gallery: gallery,
        links: formData.links,
        work_hours: formData.work_hours,
        job_category: category !== null ? category.id : formData.job_category

    }

    const dataToSend = {
        company_id : company,
        post_title: formData.job_title,
        post_status: 'publish',
        listing_type_id: category?.id,
        job_logo: logo,
        job_cover: cover,
        job_gallery: gallery,
        meta: updatedMeta
    }

    return dataToSend
}
export const handleEditSubmit = (formData, category, company, dispatch, onSubmit) => {
    const dataToSend = formatData(formData, company, category)
    
    dispatch(onSubmit(company, dataToSend))
};

export const handlePostSubmit = (formData, category, company, dispatch, onSubmit) => {
    const dataToSend = formatData(formData, company, category)

    dispatch(onSubmit(dataToSend))
}