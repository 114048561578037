import { useNavigate } from "react-router-dom";
import {
  	Grid,
  	Card,
  	CardContent,
  	Typography,
  	Tooltip,
  	IconButton,
	CardMedia,
	Box,
	Link
} from '@mui/material';
import {
	Clear,
	Visibility,
	VisibilityOff,
	ContentCopy,
	Edit,
	Launch,
} from '@mui/icons-material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {useTranslation} from "react-i18next";

import {deleteVacancy, patchVacancy} from '../../../../store/action-creators/vacancy';
import VacancyForm from '../vacancy-form/vacancy-form';
import classesCard from "../../../../css/Card.module.scss";



const VacancyCard = ({ vacancy, onCopy, onChange, onEdit }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// const editorTypes = {
	// 	edit: 'edit',
	// 	copy: 'copy',
	// }

	// const [editorType, setEditorType] = useState(editorTypes.edit)
	const [isEditorOpened, setIsEditorOpened] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const [isHidden, setisHidden] = useState(vacancy.status == 'draft' ? true : false);
	const { t } = useTranslation();

	const handleVisionChange = (id) => {
		let updatedVacancy = {...vacancy}
		if (vacancy.status === 'draft') {
			updatedVacancy = {id: id, post_status: 'publish' };
			setisHidden(false)
		} else {
			updatedVacancy = { id: id, post_status: 'draft' };
			setisHidden(true)
		}
		dispatch(patchVacancy(id, updatedVacancy))
		// onChange(updatedVacancy);
	}




	const handleDelete = (id) => {
		dispatch(deleteVacancy(id))
		setIsDelete(true)
	}

	const handleEditor = (vacancy) => {
		setIsEditorOpened(true)
		onEdit(vacancy)
	}


	const handleCopy = (vacancy) => {
		setIsEditorOpened(true)
		onCopy(vacancy)
	}



	return (
		<Grid item xs={12}  sx={{ marginTop: '10px', height: '140px'}} className={isDelete ? classesCard.hideCard : ''}>
			<Card sx={{ display: 'flex' }}>

				<CardMedia
					component="img"
					sx={{ width: '100px', objectFit: 'contain', marginLeft: '20px' }}
					image={vacancy.employer_logo}
				/>
				<Box sx={{ display: 'flex', width: '100%' }}>
					<CardContent sx={{ flex: '1 0 auto' }}>
						<Typography gutterBottom variant="h6">
							{vacancy.title}

						</Typography>
						<Typography variant="subtitle2">
							{t('status')}: {vacancy.status !== 'draft' ? t('Publish') : t('Draft')}
						</Typography>
						<Typography variant="subtitle2">
							{t("By company")} «{vacancy.employertitle}»
						</Typography>
						<Typography variant="subtitle2">
							{t("applicants")}: {vacancy.applicants_count}
						</Typography>

						<Typography variant="body1">
							{/* Responses: {vacancy.responses.length} */}


						</Typography>
					</CardContent>
							<CardContent sx={{ position: 'relative' }}>
								<Box sx={{
									position: 'absolute',
									top: 4,
									right: 4,
									display: 'flex'
								}} >
									<Tooltip title={t("Edit")}>
										{/*<IconButton onClick={() => setIsEditorOpened(true)}>*/}
										<IconButton onClick={() => handleEditor(vacancy)}>
											<Edit />
										</IconButton>
									</Tooltip>
									<Tooltip title={t("Copy")}>
										<IconButton onClick={() => handleCopy(vacancy)}>
											<ContentCopy />
										</IconButton>
									</Tooltip>

									<Tooltip title={vacancy.status == 'publish' ?  t("Make hidden") : t("Make visible")}>
										<IconButton onClick={() => handleVisionChange(vacancy.id)}>
											{isHidden ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</Tooltip>

									<Tooltip title={t("Delete")}>
										<IconButton onClick={() => handleDelete(vacancy.id)}>
											<Clear />
										</IconButton>
									</Tooltip>
								</Box>
							</CardContent>


				</Box>
			</Card>
		</Grid>
	);
};

export default VacancyCard;
