// import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Grid, 
    TextField,
    Button, 
    Card,
    CardContent,
    Typography
} from '@mui/material';

import useForm from '../../hooks/use-form';
import signUpValidate from '../../utils/validate-rules/sign-up-validation-rules';
import classes from '../../css/Auth.module.scss'
import {useDispatch, useSelector} from 'react-redux';
import { register } from '../../store/action-creators/auth';
import CircularProgress from "@mui/material/CircularProgress";

const selectIsLoadingRegister = state => state.auth.isLoadingRegister;

const SignUp = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const isLoadingRegister = useSelector(selectIsLoadingRegister)

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(() => dispatch(register(values.signUpEmail, values.signUpPassword, values.signUpCompanyName)), signUpValidate);

    return(
        <Grid item md={6}>
            <Card sx={{ minWidth: 275, borderRadius: '13px', boxShadow: 'none' }}
                className={classes.AuthContainer_AuthCard}
            >
                <CardContent sx={{padding: '24px'}}>
                    <Typography component="div" variant="h5">{t('signUp')}</Typography>
                    <TextField
                        required
                        fullWidth
                        margin='normal'
                        variant="filled"
                        id='signUpCompanyName'
                        label={t('companyName')}
                        name='signUpCompanyName'
                        type='text'
                        onChange={handleChange}
                        className={classes.AuthContainer_AuthCard__input}
                        value={values.signUpCompanyName || ''}
                        helperText={errors.signUpCompanyName}
                        error={Boolean(errors.signUpCompanyName)}
                    />
                    <TextField
                        required
                        fullWidth
                        margin='normal'
                        variant="filled"
                        id='signUpEmail'
                        label={t('email')}
                        name='signUpEmail'
                        type='email'
                        onChange={handleChange}
                        className={classes.AuthContainer_AuthCard__input}
                        value={values.signUpEmail || ''}
                        helperText={errors.signUpEmail}
                        error={Boolean(errors.signUpEmail)}
                    />
                    <TextField
                        required
                        fullWidth
                        margin='normal'
                        variant='filled'
                        id='signUpPassword'
                        label={t('password')}
                        name='signUpPassword'
                        type='password'
                        onChange={handleChange}
                        className={classes.AuthContainer_AuthCard__input}
                        value={values.signUpPassword || ''}
                        helperText={errors.signUpPassword}
                        error={Boolean(errors.signUpPassword)}
                    />
                    <TextField
                        required
                        fullWidth
                        margin='normal'
                        variant='filled'
                        id='signUpConfirmPassword'
                        label={t('confirmPassword')}
                        name='signUpConfirmPassword'
                        type='password'
                        onChange={handleChange}
                        className={classes.AuthContainer_AuthCard__input}
                        value={values.signUpConfirmPassword || ''}
                        helperText={errors.signUpConfirmPassword}
                        error={Boolean(errors.signUpConfirmPassword)}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, color: 'white' }}
                        onClick={handleSubmit}
                        disabled={isLoadingRegister}
                    >
                        {isLoadingRegister ? <CircularProgress sx={{  position: 'absolute', left: '10px', color: '#f98b27'  }} size={14} /> : ""}
                        {t('signUp')}
                    </Button>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default SignUp;