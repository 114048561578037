// import axios from 'axios';
import axios from '../axios-config';
import { NEW_COMPANY_CATEGORY_FIELDS, SERVER_URL } from '../../utils/const';
import { FETCH_CATEGORIES, FETCH_CATEGORIES_ERROR, FETCH_CATEGORIES_SUCCESS, FETCH_COMPANIES, FETCH_COMPANIES_ERROR, FETCH_COMPANIES_SUCCESS, FETCH_COMPANY, FETCH_COMPANY_ERROR, FETCH_COMPANY_SUCCESS, FETCH_FIELDS, FETCH_FIELDS_ERROR, FETCH_FIELDS_SUCCESS, PATCH_COMPANY, PATCH_COMPANY_ERROR, PATCH_COMPANY_SUCCESS } from '../reducers/company-reducer';

const token = localStorage.getItem('token')

export const fetchCompanies = () => {
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_COMPANIES})
            const response = await axios.get(
                `${SERVER_URL}/acccompany/v1/my-companies`,
                {headers: {Authorization: `Bearer ${token}`}}
            )
            dispatch({
                type: FETCH_COMPANIES_SUCCESS,
                payload: response.data,
            })
        } catch (e) {
            dispatch({
                type: FETCH_COMPANIES_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const fetchCompany = (id) => {
    return async (dispatch) => {
        if (id !== undefined) {
            try {
                dispatch({type: FETCH_COMPANY})
                const response = await axios.get(
                    `${SERVER_URL}/acccompany/v1/my-company?company_id=${id}`,
                    {headers: {Authorization: `Bearer ${token}`}}
                )
                dispatch({
                    type: FETCH_COMPANY_SUCCESS,
                    payload: response.data,
                })
            } catch (e) {
                dispatch({
                    type: FETCH_COMPANY_ERROR,
                    payload: e.response?.data?.message,
                })
            }
        }
    }
}

export const fetchFields = (id) => {
    return async (dispatch) => {
        if (id !== undefined) {
            try {
                dispatch({type: FETCH_FIELDS})

                const response = await axios.get(
                    `${SERVER_URL}/acccompany/v1/fields-by-listing-type?listing_type=${id}`,
                    {headers: {Authorization: `Bearer ${token}`}}
                )
                dispatch({
                    type: FETCH_FIELDS_SUCCESS,
                    payload: Object.keys(response.data).length > 2 ? response.data : NEW_COMPANY_CATEGORY_FIELDS,
                })
            } catch (e) {
                dispatch({
                    type: FETCH_FIELDS_ERROR,
                    payload: e.response?.data?.message,
                })
            }
        }
    }
}

export const postCompany = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: PATCH_COMPANY})
            await axios.post(
                `${SERVER_URL}/acccompany/v1/my-company`,
                data,
                {headers: {Authorization: `Bearer ${token}`}}
            )
            dispatch({
                type: PATCH_COMPANY_SUCCESS
            })

            window.location.replace('/profile/catalog')
        } catch (e) {
            dispatch({
                type: PATCH_COMPANY_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const fetchCategories = () => {
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_CATEGORIES})
            const response = await axios.get(
                `${SERVER_URL}/acccompany/v1/get_categories`,
                {headers: {Authorization: `Bearer ${token}`}}
            )
            dispatch({
                type: FETCH_CATEGORIES_SUCCESS,
                payload: response.data,
            })
        } catch (e) {
            dispatch({
                type: FETCH_CATEGORIES_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const patchCompany = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({type: PATCH_COMPANY})
            const result = await axios.post(
                `${SERVER_URL}/acccompany/v1/my-company-edit?company_id=${id}`,
                data,
                {headers: {Authorization: `Bearer ${token}`, 'Content-Type': "multipart/form-data"}}
            )

            window.location.replace('/profile/catalog')
            dispatch({
                type: PATCH_COMPANY_SUCCESS
            })
        } catch (e) {
            dispatch({
                type: PATCH_COMPANY_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}
