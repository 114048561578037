import './app.css';
import { useDispatch, useSelector } from 'react-redux'
import {
	BrowserRouter,
	Routes,
	Route,
	// useNavigate
} from 'react-router-dom';
import { useEffect } from 'react';
import classes from './css/Vars.module.scss'
import { ToastContainer } from 'react-toastify'
import { useTranslation } from 'react-i18next';

import { validateToken } from './store/action-creators/auth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography, Divider } from '@mui/material';
import AuthPage from './components/auth/auth-page';
import AuthLogout from './components/auth/logout';
import ProfilePage from './components/profile/profile-page';
import CatalogSection from './components/profile/catalog/catalog-section';
import CareerSection from './components/profile/career/career-section';
import MyVacancies from './components/profile/career/my-vacancies/my-vacancies';
import NewVacancy from './components/profile/career/new-vacancy/new-vacancy';
import Contacts from './components/profile/career/contacts/contacts';
import Applicants from './components/profile/career/applicants/applicants';
import EmailCheck from './components/auth/email-check';

const selectIsLoading = state => state.auth.isLoading;

const App = () => {
	const theme = createTheme({
		palette: {
			primary: {
			  main: classes.primary ,
			  darker: '#053e85',
			},
			neutral: {
			  main: '#64748B',
			  contrastText: '#fff',
			},
		  },
	  });

	const isLoading = useSelector(selectIsLoading)
	const dispatch = useDispatch()
	const { i18n } = useTranslation();
	// const navigate = useNavigate()

	//
	useEffect(() => {
		const token = localStorage.getItem('token')
		if(localStorage.length === 0) {
			dispatch(validateToken(token))
		}
	},[])

	// useEffect(() => {
	// 	const selectedLanguage = localStorage.getItem('selectedLanguage');
	// 	if (selectedLanguage) {
	// 	  i18n.changeLanguage(selectedLanguage);
	// 	} else {
	// 	  i18n.changeLanguage('en');
	// 	  localStorage.setItem('selectedLanguage', 'en');
	// 	}
	//   }, [i18n]);

	if (isLoading) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100vh', alignItems: 'center' }}>
				<Box>
					<CircularProgress sx={{ width: '200px', color: '#f98b27' }} size={100} />
					<Typography sx={{ textAlign: 'center', marginTop: '20px' }} gutterBottom variant="p" component="div">
						Loading...
					</Typography>
					</Box>
			</Box>
		)
	}

	return(
		<ThemeProvider theme={theme}>
 			<BrowserRouter>
                <Routes>
					<Route path='/' element={localStorage.getItem('token') ? <ProfilePage /> : <AuthPage />} />
					<Route path='/confirm-email/:token' element={<EmailCheck />} />
                    <Route path="/auth" element={<AuthPage />} />
					<Route path="/logout" element={<AuthLogout />} />
                    <Route path="/profile/*" element={<ProfilePage />}>
                        <Route path="catalog/*" element={<CatalogSection />} />
                        <Route path='career/*' element={<CareerSection />}>
							<Route
								path='new-vacancy'
								element={<NewVacancy />}
							/>
							<Route
								path='my-vacancies'
								element={<MyVacancies />}
							/>
							<Route
								path='contacts'
								element={<Contacts />}
							/>
							<Route
								path='applicants'
								element={<Applicants />}
							/>
							{/* <Route
								path='applicants'
								element={<Applicants />}
							/> */}
						</Route>
					</Route>
                </Routes>
            </BrowserRouter>
			<ToastContainer
                  	position="bottom-left"
            />
		</ThemeProvider>
	)
}

export default App;
