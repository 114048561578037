import axios from 'axios';

import {Navigate, Route, useNavigate} from 'react-router-dom';
import * as PropTypes from "prop-types";
export const SERVER_URL = 'https://move2armenia.am/wp-json';

export const PHONE_REGEX = /^\+\d+$/
export const URL_REGEX = /^(?!.*@).*\..+$/;
export const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

export const MAX_ACTIVE_VACANCIES = 10;

export const RESPONSE_REPLY_TYPE = {
    ACCEPT: 'accept',
    REJECT: 'reject',
};

export const LANGUAGES = [
    'english',
    'armenian',
    'russian',
];

export const LANGUAGE_OPTIONS = [
    '– (No proficiency)',
    'A1 (Beginner)',
    'A2 (Elementary)',
    'B1 (Intermediate)',
    'B2 (Upper-Intermediate)',
    'C1 (Advanced)',
    'C2 (Proficiency)',
    'Native'
];

export const TYPE_OPTIONS = [
    'Freelance',
    'Full Time',
    'Internship',
    'Part Time',
    'Temporary',
];

export const EXPERIENCE_OPTIONS = [
    '0-2 Years',
    '2-4 Years',
    '4-6 Years',
    '6+ Years',
];

export const SALARY_OPTIONS = [
    'Monthly',
    'Weekly',
    'Daily',
    'Hourly',
    'Yearly',
];

export const CURRENCY_OPTIONS = [
    'USD',
    'AMD',
];

export const EMPLOYMENT_OPTIONS = [
    'Full-time',
    'Part-time',
    'Contract',
];

export const WORK_TYPE_OPTIONS = [
    'On-site',
    'Remote',
];

export const REMOTE_WORK_OPTIONS = [
    'yes',
    'no',
];

export const JOB_STATUS_OPTIONS = [
    'open',
    'closed',
    'expired',
];

export const NEW_COMPANY_CATEGORY_FIELDS = {
    "job_title": {
        "type": "text",
        "slug": "job_title",
        "default": "",
        "priority": 1,
        "is_custom": false,
        "label": "Заголовок",
        "default_label": "Заголовок",
        "placeholder": "",
        "description": "",
        "required": true,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [],
        "minlength": "",
        "maxlength": "",
        "content_lock": false
    },
    "job_description": {
        "type": "texteditor",
        "slug": "job_description",
        "default": "",
        "priority": 2,
        "is_custom": false,
        "label": "Описание",
        "default_label": "Описание",
        "placeholder": "",
        "description": "",
        "required": true,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ],
        "editor-controls": "basic",
        "allow-shortcodes": false,
        "minlength": "",
        "maxlength": "",
        "editor-type": "wp-editor"
    },
    "price_range": {
        "type": "select",
        "slug": "price_range",
        "default": "",
        "priority": 3,
        "is_custom": false,
        "label": "Диапазон цен",
        "default_label": "Диапазон цен",
        "placeholder": "",
        "description": "",
        "required": false,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ],
        "options": {
            "$": "$",
            "$$": "$$",
            "$$$": "$$$"
        }
    },
    "job_category": {
        "type": "term-select",
        "slug": "job_category",
        "default": "",
        "priority": 4,
        "is_custom": false,
        "label": "Категория",
        "default_label": "Категория",
        "placeholder": "",
        "description": "",
        "required": false,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ],
        "taxonomy": "job_listing_category",
        "terms-template": "multiselect",
        "create_tag": false
    },
    "work_hours": {
        "type": "work-hours",
        "slug": "work_hours",
        "default": "",
        "priority": 5,
        "is_custom": false,
        "label": "Часы работы",
        "default_label": "Часы работы",
        "placeholder": "",
        "description": "",
        "required": false,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ]
    },
    "job_location": {
        "type": "location",
        "slug": "job_location",
        "default": "",
        "priority": 7,
        "is_custom": false,
        "label": "Локация",
        "default_label": "Локация",
        "placeholder": "например \"Каварна\"",
        "description": "",
        "required": false,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ],
        "map-skin": false,
        "map-zoom": 12,
        "map-default-location": {
            "lat": 0,
            "lng": 0
        }
    },
    // "region": {
    //     "type": "term-select",
    //     "slug": "region",
    //     "default": "",
    //     "priority": 8,
    //     "is_custom": false,
    //     "label": "Район Еревана",
    //     "default_label": "Region",
    //     "placeholder": "",
    //     "description": "",
    //     "required": false,
    //     "show_in_admin": true,
    //     "show_in_submit_form": true,
    //     "show_in_compare": true,
    //     "conditional_logic": false,
    //     "conditions": [
    //         [
    //             {
    //                 "key": "__listing_package",
    //                 "compare": "==",
    //                 "value": ""
    //             }
    //         ]
    //     ],
    //     "taxonomy": "region",
    //     "terms-template": "multiselect",
    //     "create_tag": false
    // },
    "job_phone": {
        "type": "text",
        "slug": "job_phone",
        "default": "",
        "priority": 9,
        "is_custom": false,
        "label": "Телефон",
        "default_label": "Телефон",
        "placeholder": "",
        "description": "",
        "required": false,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ],
        "minlength": "",
        "maxlength": "",
        "content_lock": false
    },
    "job_website": {
        "type": "url",
        "slug": "job_website",
        "default": "",
        "priority": 10,
        "is_custom": false,
        "label": "Веб Сайт",
        "default_label": "Веб Сайт",
        "placeholder": "",
        "description": "",
        "required": false,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ]
    },
    "job_email": {
        "type": "email",
        "slug": "job_email",
        "default": "",
        "priority": 11,
        "is_custom": false,
        "label": "Контактный Email",
        "default_label": "Контактный Email",
        "placeholder": "",
        "description": "",
        "required": false,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ],
        "content_lock": false
    },
    "links": {
        "type": "links",
        "slug": "links",
        "default": "",
        "priority": 12,
        "is_custom": false,
        "label": "Социальные аккаунты",
        "default_label": "Социальные аккаунты",
        "placeholder": "",
        "description": "",
        "required": false,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ]
    },
    "job_gallery": {
        "type": "file",
        "slug": "job_gallery",
        "default": "",
        "priority": 13,
        "is_custom": false,
        "label": "Галерея",
        "default_label": "Галерея",
        "placeholder": "",
        "description": "",
        "required": false,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ],
        "ajax": true,
        "multiple": true,
        "file_limit": "",
        "allowed_mime_types": {
            "jpg": "image/jpeg",
            "jpeg": "image/jpeg",
            "gif": "image/gif",
            "png": "image/png",
            "webp": "image/webp"
        },
        "allowed_mime_types_arr": []
    },
    "job_cover": {
        "type": "file",
        "slug": "job_cover",
        "default": "",
        "priority": 14,
        "is_custom": false,
        "label": "Основное изображение",
        "default_label": "Основное изображение",
        "placeholder": "",
        "description": "",
        "required": false,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ],
        "ajax": true,
        "multiple": false,
        "file_limit": "",
        "allowed_mime_types": {
            "jpg": "image/jpeg",
            "jpeg": "image/jpeg",
            "gif": "image/gif",
            "png": "image/png",
            "webp": "image/webp"
        },
        "allowed_mime_types_arr": []
    },
    "job_logo": {
        "type": "file",
        "slug": "job_logo",
        "default": "",
        "priority": 15,
        "is_custom": false,
        "label": "Лого",
        "default_label": "Лого",
        "placeholder": "",
        "description": "",
        "required": true,
        "show_in_admin": true,
        "show_in_submit_form": true,
        "show_in_compare": true,
        "conditional_logic": false,
        "conditions": [
            [
                {
                    "key": "__listing_package",
                    "compare": "==",
                    "value": ""
                }
            ]
        ],
        "ajax": true,
        "multiple": false,
        "file_limit": "",
        "allowed_mime_types": {
            "jpg": "image/jpeg",
            "jpeg": "image/jpeg",
            "gif": "image/gif",
            "png": "image/png",
            "webp": "image/webp"
        },
        "allowed_mime_types_arr": []
    },
    // "job_video_url": {
    //     "type": "url",
    //     "slug": "job_video_url",
    //     "default": "",
    //     "priority": 16,
    //     "is_custom": false,
    //     "label": "URL видео",
    //     "default_label": "URL видео",
    //     "placeholder": "",
    //     "description": "",
    //     "required": false,
    //     "show_in_admin": true,
    //     "show_in_submit_form": true,
    //     "show_in_compare": true,
    //     "conditional_logic": false,
    //     "conditions": [
    //         [
    //             {
    //                 "key": "__listing_package",
    //                 "compare": "==",
    //                 "value": ""
    //             }
    //         ]
    //     ]
    // },
    // "gorod": {
    //     "type": "term-select",
    //     "slug": "gorod",
    //     "default": "",
    //     "priority": 17,
    //     "is_custom": false,
    //     "label": "Город",
    //     "default_label": "Город",
    //     "placeholder": "",
    //     "description": "",
    //     "required": false,
    //     "show_in_admin": true,
    //     "show_in_submit_form": true,
    //     "show_in_compare": true,
    //     "conditional_logic": false,
    //     "conditions": [
    //         [
    //             {
    //                 "key": "__listing_package",
    //                 "compare": "==",
    //                 "value": ""
    //             }
    //         ]
    //     ],
    //     "taxonomy": "gorod",
    //     "terms-template": "multiselect",
    //     "create_tag": false
    // },
}





// axios.interceptors.request.use(function (config) {
//     if(localStorage.length == 0){
//         console.log('no auth')
//     }
//
//
//     return config;
// }, function (error) {
//     return Promise.reject(error);
// });
