import React, { useState, useEffect } from 'react';
import { Tabs, Tab, RadioGroup, Radio, FormControlLabel, Select, MenuItem, Grid } from '@mui/material';
import { t } from 'i18next';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const generateHours = () => {
    const timeOptions = [];

    for (let hours = 0; hours <= 23; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const formattedHours = String(hours).padStart(2, '0')
        const formattedMinutes = String(minutes).padStart(2, '0')
        const time = `${formattedHours}:${formattedMinutes}`
        timeOptions.push(time);
      }
    }

    return timeOptions
};

const hours = generateHours();

const initialState = {
    "Monday": {
        "status": "open-all-day",
    },
    "Tuesday": {
        "status": "open-all-day"
    },
    "Wednesday": {
        "status": "open-all-day",
    },
    "Thursday": {
        "status": "open-all-day"
    },
    "Friday": {
        "status": "open-all-day",
    },
    "Saturday": {
        "status": "open-all-day"
    },
    "Sunday": {
        "status": "open-all-day",
    },
}

const WorkTimeInput = ({ data, cb }) => {
    const [schedule, setSchedule] = useState(initialState);
    const [activeTab, setActiveTab] = useState(daysOfWeek[0]);

    useEffect(() => {
        if (data !== undefined) {
            setSchedule(data)
        }
    }, [data])

    const handleScheduleChange = (day, value) => {
        setSchedule((prevSchedule) => {
            const updatedSchedule = {
                ...prevSchedule,
                [day]: {
                ...prevSchedule[day],
                status: value
                }
            };
            cb(updatedSchedule)
        });
    };

    const handleSelectChange = (day, field, value) => {
        setSchedule((prevSchedule) => ({
            ...prevSchedule,
            [day]: {
                ...prevSchedule[day],
                0: {
                ...prevSchedule[day][0],
                [field]: value
                }
            }
        }));
    };

    const handleTabChange = (e, newTab) => {
        setActiveTab(newTab);
    };

    return (
        <>
        <Tabs value={activeTab} onChange={handleTabChange}>
            {daysOfWeek.map((day) => (
                <Tab key={day} label={t(day)} value={day} />
            ))}
        </Tabs>

        {daysOfWeek.map((day) => (
            <div key={day} role='tabpanel' hidden={activeTab !== day}>
            {activeTab === day && (
                <RadioGroup
                    value={schedule[day]?.status}
                    onChange={(e) => handleScheduleChange(day, e.target.value)}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                >
                <FormControlLabel value='enter-hours' control={<Radio />} label={t('enterHours')} />
                <FormControlLabel value='open-all-day' control={<Radio />} label={t('openAllDay')} />
                <FormControlLabel value='closed-all-day' control={<Radio />} label={t('closedAllDay')} />
                <FormControlLabel value='by-appointment-only' control={<Radio />} label={t('byAppointmentOnly')}/>

                {schedule[day]?.status === 'enter-hours' && (
                    <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Select
                        fullWidth
                        value={schedule[day]?.[0]?.from || ''}
                        label={t('from')}
                        onChange={(e) => handleSelectChange(day, 'from', e.target.value)}
                        >
                        {hours.map((hour) => (
                            <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                        ))}
                        </Select>
                    </Grid>

                    <Grid item xs={6}>
                        <Select
                        fullWidth
                        value={schedule[day]?.[0]?.to || ''}
                        label={t('to')}
                        onChange={(e) => handleSelectChange(day, 'to', e.target.value)}
                        >
                        {hours.map((hour) => (
                            <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                        ))}
                        </Select>
                    </Grid>
                    </Grid>
                )}
                </RadioGroup>
            )}
            </div>
        ))}
        </>
    );
};

export default WorkTimeInput;
