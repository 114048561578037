import React, { useState, useEffect } from 'react';
import { Box, TextField, Select, MenuItem, InputLabel, FormControl, Button, Snackbar, Alert, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactSelect from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { MuiFileInput } from 'mui-file-input';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchCompany, fetchFields, patchCompany, postCompany } from '../../../../store/action-creators/company';
import { fetchOptions } from '../../../../utils/utils';
import M2ALoader from '../../../templateParts/loader';
import classesCard from '../../../../css/Card.module.scss';
import WorkTimeInput from './form-inputs/work-time';
import SocialLinksInput from './form-inputs/social-links';
import { updateInputValues, handleFieldChange, handlePostSubmit, handleEditSubmit } from './utils';
import { EMAIL_REGEX, PHONE_REGEX, URL_REGEX } from '../../../../utils/const';

const selectCompany = (state) => state.company.company;
const selectFields = (state) => state.company.fields;
const selectIsCompanyLoading = (state) => state.company.isCompanyLoading;
const selectIsFieldsLoading = (state) => state.company.isFieldsLoading;
const selectIsLoading = (state) => state.company.isLoading

const CompanyInfoForm = ({ type, companyId, category, newCategory = null, onClose }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const company = useSelector(selectCompany);
    const fields = useSelector(selectFields);
    const isCompanyLoading = useSelector(selectIsCompanyLoading);
    const isFieldsLoading = useSelector(selectIsFieldsLoading);

    const isSubmitting = useSelector(selectIsLoading)

    const [formData, setFormData] = useState({});
    const [rawSelectOptions, setRawSelectOptions] = useState([])
    const [rawSelectOptionsObj, setRawSelectOptions1] = useState([])
    const [selectOptions, setSelectOptions] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const [fileInputChanges, setFileInputChanges] = useState({});

    useEffect(() => {
        if (type === 'edit') {
            dispatch(fetchCompany(companyId));
        }

        if (type === 'post' && typeof category.name === 'string') {
            dispatch(fetchFields(category.id));
        }

    }, []);

    useEffect(() => {
        type === 'edit' && dispatch(fetchFields(company.listing_type_id));

    }, [company.listing_type_id, dispatch, type]);

    useEffect(() => {
        fetchOptions(fields, setRawSelectOptions)
    }, [fields]);

    useEffect(() => {
        
            if (rawSelectOptions.length) {
                const formatedData = {};
                rawSelectOptions.forEach((item) => 
                    (formatedData[item.term] = item.values)
                )
                setRawSelectOptions1(formatedData)
            } 
        
    }, [rawSelectOptions])

    useEffect(() => {
        if (rawSelectOptionsObj !== {}) {
            const entries = Object.entries(rawSelectOptionsObj);
            const formattedSelectObj = {}
            for (const [key, value] of entries) {
                formattedSelectObj[key] = value.map(item => item = {value: item.name, label: item.name})
              }

            setSelectOptions(formattedSelectObj)
        }
    }, [rawSelectOptionsObj])

    useEffect(() => {
        type === 'edit' && updateInputValues(company.meta, setFormData);
        type === 'post' && setFormData((prevFormData) => ({
                ...prevFormData,
                job_category: category,
            }))

    }, [company.meta, type]);

    const checkStringInObjectValues = (obj, searchString) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].includes(searchString)) {
                return true;
            }
        }
        return false;
    }

    const validateFileType = (fieldName, file) => {
        if (fieldName !== 'upload-pdf') {
            const field = fields[fieldName];
            const allowedTypes = Object.values(field.allowed_mime_types);
            const fileType = file && file.type;

            if (!allowedTypes.includes(fileType)) {
                return `${field.label}${ t('must be a valid file type') }(${allowedTypes.join(', ')}).`;
            }

            return null
        } 

        if (fieldName === 'upload-pdf') {
            const field = fields[fieldName];
            const allowedTypes = field.allowed_mime_types;
            if (file[0] === undefined) {
                return
            }
            const fileName = file && file[0].name;
            const fileExtension = fileName && fileName.split('.').pop().toLowerCase();
            const fileType = allowedTypes[fileExtension];
    
            if (!fileType) {
              return `${field.label} ${t('must be a valid file type')} (${Object.values(allowedTypes).join(', ')}).`;
            }
          
            return null;
        }
    };

    const validateForm = () => {
        const errors = {};

        Object.keys(fields).forEach((fieldName) => {
            const field = fields[fieldName];
            
            if (field.show_in_submit_form !== false && field.required && !formData[fieldName]) {
                errors[fieldName] = `${field.label} ${t('is required')}.`;
            }

            if (field.slug === 'job_phone' && !PHONE_REGEX.test(formData[fieldName]) && formData[fieldName]) {
                errors[fieldName] = `${field.label} ${t('invalidPhoneNumber')}.`;
            }
            if (field.slug === 'job_website' && !URL_REGEX.test(formData[fieldName]) && formData[fieldName]) {
                errors[fieldName] = `${field.label} ${t('invalidUrl')}.`;
            }
            if (field.slug === 'job_email' && !EMAIL_REGEX.test(formData[fieldName]) && formData[fieldName]) {
                errors[fieldName] = `${field.label} ${t('invalidEmail')}.`;
            }

            if (field.type === 'file' && formData[fieldName] && fieldName !== 'job_gallery') {
                if (fileInputChanges[fieldName]) {
                    // const fileError = fieldName === 'job_gallery' ? validateFileType(fieldName, formData[fieldName][0]) : validateFileType(fieldName, formData[fieldName]);
                    const fileError = validateFileType(fieldName, formData[fieldName]);
                    if (fileError) {
                        errors[fieldName] = fileError;
                    }
                }
            }

            if (fieldName === 'job_gallery' && formData[fieldName]) {
                if (fileInputChanges[fieldName]) {
                    const fileError = validateFileType(fieldName, formData[fieldName][0]);
                    if (fileError) {
                        errors[fieldName] = fileError;
                    }
                }
            }
        
        });

        return errors;
    };

    const handleFileInputChange = (fieldName, value) => {
        setFileInputChanges((prevState) => ({
        ...prevState,
        [fieldName]: true,
        }));
        handleFieldChange(fieldName, value, setFormData);
    };

    const handleSubmit = () => {
        const errors = validateForm();
        const currentCategory = newCategory ? newCategory : category

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
        } else {
            setValidationErrors({});
            type === 'edit' && handleEditSubmit(formData, currentCategory, companyId, dispatch, patchCompany);
            type === 'post' && handlePostSubmit(formData, currentCategory, companyId, dispatch, postCompany);
        }
    };

    if (isCompanyLoading || isFieldsLoading) {
        return <M2ALoader />;
    }

    return (
        <>
            <Box className={classesCard.editorCard}>
                <IconButton
                    onClick={onClose}
                >
                    <ArrowBackIcon />
                </IconButton>
                {Object.keys(fields).map((fieldName) => {
                const field = fields[fieldName];
                const fieldError = validationErrors[fieldName];

                if (!field.show_in_submit_form) {
                    return null;
                }

                let fieldComponent;

                if (field.type === 'select') {
                    fieldComponent = (
                        <FormControl key={field.slug} fullWidth variant="filled" sx={{ marginTop: 2 }}>
                            <InputLabel id={`${fieldName}-label`}>{field.label}</InputLabel>
                            <Select
                                className={classesCard.editorCard__m2a_select}
                                labelId={`${fieldName}-label`}
                                id={fieldName}
                                name={field.slug}
                                multiple={field.multiple}
                                value={formData[fieldName] || ''}
                                onChange={(event) => handleFieldChange(fieldName, event.target.value, setFormData)}
                                error={!!fieldError}
                            >
                                {Object.entries(field.options).map(([key, value]) => (
                                    <MenuItem key={key} value={value}>
                                    {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    );
                } else if (field.type === 'file') {
                    fieldComponent = (
                        <MuiFileInput
                            key={field.slug}
                            sx={{ marginTop: 2 }}
                            fullWidth
                            id={fieldName}
                            name={field.slug}
                            label={field.label}
                            multiple={field.multiple}
                            value={formData[fieldName] || ''}
                            // onChange={(value) => handleFieldChange(fieldName, value, setFormData)}
                            onChange={(value) => handleFileInputChange(fieldName, value, setFormData)}
                            error={!!fieldError}
                            helperText={fieldError}
                        />
                    );
                } else if (field.type === 'texteditor') {
                    fieldComponent = (
                        <FormControl key={field.slug} fullWidth sx={{ marginTop: 2 }}>
                            <InputLabel
                                id={`${fieldName}-label`}
                                sx={{
                                    position: 'inherit',
                                    marginBottom: '30px',
                                    left: '0px',
                                    marginLeft: '-10px',
                                }}
                            >
                                {field.label}
                            </InputLabel>
                            <ReactQuill
                                value={formData[fieldName] || ''}
                                onChange={(value) => handleFieldChange(fieldName, value, setFormData)}
                                required={field.required}
                                name={field.slug}
                                error={!!fieldError}
                            />
                        </FormControl>
                    );
                } else if (field.type === 'term-select' && type !== 'edit' && fieldName !== 'job_category') {
                    fieldComponent = (
                        <FormControl
                            fullWidth
                            key={field.slug}
                            sx={{ marginTop: 2 , zIndex: 100 - field.priority}}
                            error={!!fieldError}
                        >
                            <ReactSelect
                                isDisabled={selectOptions[fieldName] === undefined}
                                id={fieldName}
                                name={field.slug}
                                isMulti={checkStringInObjectValues(field, 'multiselect')}
                                options={selectOptions[field.taxonomy] || []}
                                placeholder={` ${field.label.split('-').pop().trim()}`}
                                isClearable
                                isSearchable
                                onChange={(selectedOption) => handleFieldChange(fieldName, selectedOption, setFormData)}
                            />
                            {selectOptions[field.taxonomy] === undefined
                            && <CircularProgress sx={{ position: 'absolute', right: '5%', color: '#f98b27' }} size={14} />}
                        </FormControl>
                    );

                } else if (field.type === 'term-select' && type !== 'post') {
                    fieldComponent = (
                        <FormControl
                            fullWidth
                            key={field.slug}
                            sx={{ marginTop: 2 , zIndex: 100 - field.priority}}
                            error={!!fieldError}
                        >
                            <ReactSelect
                                isDisabled={selectOptions[field.taxonomy] ===  undefined}
                                id={fieldName}
                                name={field.slug}
                                isMulti={checkStringInObjectValues(field, 'multiselect')}
                                // options={(fieldName === 'job_category' ? selectOptions['job_listing_category'] : selectOptions[fieldName]) || []}
                                options={selectOptions[field.taxonomy] || []}
                                placeholder={` ${field.label.split('-').pop().trim()}`}
                                isClearable
                                isSearchable
                                onChange={(selectedOption) => handleFieldChange(fieldName, selectedOption, setFormData)}
                            />
                            {/* {(selectOptions[fieldName] === undefined || selectOptions['job_listing_category'] === undefined) */}
                            {selectOptions[field.taxonomy] === undefined
                            && <CircularProgress sx={{ position: 'absolute', right: '5%', color: '#f98b27' }} size={14} />}
                        </FormControl>
                    );
                } else if (fieldName === 'job_category' && type !== 'edit') {
                    fieldComponent = (
                        <TextField
                            key={fieldName}
                            fullWidth
                            disabled
                            sx={{ marginTop: 2 }}
                            variant="filled"
                            id={fieldName}
                            name={field.slug}
                            label={field.label}
                            type='text'
                            required={field.required}
                            value={(newCategory ? newCategory : category.name) || ''}
                            onChange={(event) => handleFieldChange(fieldName, event.target.value, setFormData)}
                            error={!!fieldError}
                            helperText={fieldError}
                        />
                    );
                } else if (field.type === 'work-hours') {
                    fieldComponent = (
                        <WorkTimeInput
                            key={field.slug}
                            data={formData[fieldName]}
                            cb={(data) => handleFieldChange(fieldName, data, setFormData)}
                            error={!!fieldError}
                            helperText={fieldError}
                        />
                    );
                } else if (field.type === 'links') {
                    fieldComponent = (
                        <SocialLinksInput
                            key={field.slug}
                            data={formData[fieldName]}
                            cb={(data) => handleFieldChange(fieldName, data, setFormData)}
                            error={!!fieldError}
                            helperText={fieldError}
                        />
                    );
                } else {
                    fieldComponent = (
                        <TextField
                            key={fieldName}
                            fullWidth
                            sx={{ marginTop: 2 }}
                            variant="filled"
                            id={fieldName}
                            name={field.slug}
                            label={field.label}
                            type={field.slug === 'job_phone' ? 'tel' : field.type}
                            required={field.required}
                            value={formData[fieldName] || (field.type === 'date' ? new Date().toISOString().slice(0, 10) : '')}
                            onChange={(event) => handleFieldChange(fieldName, event.target.value, setFormData)}
                            error={!!fieldError}
                            helperText={fieldError}
                        />
                    );
                }
                return fieldComponent;
                })}

                <Button
                    sx={{ mt: 3, mb: 2 }}
                    fullWidth
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? <CircularProgress sx={{  position: 'absolute', left: '10px', color: '#f98b27'  }} size={14} /> : ""}
                    {t('save')}
                    
                </Button>

                <Button
                    sx={{ mt: 3, mb: 2 }}
                    fullWidth
                    variant="contained"
                    onClick={onClose}
                >
                    {t('cancel')}
                </Button>
            </Box>

            <Snackbar
                open={Object.keys(validationErrors).length > 0}
                autoHideDuration={10000}
                onClose={() => setValidationErrors({})}
            >
                <Alert
                    onClose={() => setValidationErrors({})}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {t('Please fix the following errors:')}
                    {Object.keys(validationErrors).map((fieldName) => (
                        <div key={fieldName}>{validationErrors[fieldName]}</div>
                    ))}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CompanyInfoForm;
