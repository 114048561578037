import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import classesCard from "../../../../css/Card.module.scss"

const CategoryInput = ({ categories, selectedCategory, onChange, onSubmit }) => {
    const { t } = useTranslation()

    return (
        <>
            <FormControl fullWidth variant='filled' margin='normal'>
                <InputLabel id='newCompanyCardCategoryLabel'>
                    {t('selectCompanyCategory')}
                </InputLabel>
                <Select
                    className={classesCard.editorCard__m2a_select}
                    labelId='newCompanyCardCategoryLabel'
                    id='newCompanyCardCategory'
                    name='companyCategory'
                    value={selectedCategory || ''}
                    onChange={(e) => onChange(e.target.value)}
                >
                    <MenuItem value={'newCategory'}>
                        {t('Добавить новую категорию')}
                    </MenuItem>
                    {categories.map((category) => (
                        <MenuItem key={category.term_id} value={{id: category.lising_type, name: category.name}}>
                            {t(category.name)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                onClick={() => onSubmit(true)}
            >
                {t('selectCategory')}
            </Button>
        </>
    )
}

export default CategoryInput;
