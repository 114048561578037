import { useState } from 'react';
import {
	TextField,
	Button,
	Typography,
	Modal,
	Box,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { patchApplicant } from '../../../../store/action-creators/applicant';
// import { Context } from '../../..';
import classesCard from "../../../../css/Card.module.scss"
import {useTranslation} from "react-i18next";

const ApplicantModalReply = ({responseId, responseType ,company, onOpen, onClose, updateCb}) => {
    const { email, phone } = company;
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [replyEmail, setReplyEmail] = useState(email);
    const [replyPhone, setReplyPhone] = useState(phone);
    const [replyText, setReplyText] = useState('');
    const handleApplicantReply = () => {
        let status = 'thinking';
        responseType === 'accept' ? status = 'approved' : status = 'rejected';
        
        const dataToSend = {
            'applicant_status' : status,
            'approve_message' : replyText,
            'approve_contact' : `${replyEmail} ${replyPhone}`,
        };

        // store.replyApplicant(responseId, dataToSend)
        dispatch(patchApplicant(responseId, dataToSend))

        onClose();
        updateCb()
    }

    return (
        <Modal open={onOpen} onClose={onClose}>
            {/* <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
             */}
            <Box className={classesCard.modal_sure}>
                {
                    responseType === 'accept' && (
                        <>
                            <Typography variant='subtitle1' component='div'>
                                {t('Contacts')}
                            </Typography>
                            <TextField margin={'normal'} label='Email' fullWidth value={replyEmail} onChange={(e) => setReplyEmail(e.target.value)}/>
                            <TextField margin={'normal'} label='Phone' fullWidth value={replyPhone} onChange={(e) => setReplyPhone(e.target.value)}/>
                        </>
                    )
                }
                <Typography variant='suntitle1' component='div' sx={{ mt: 2 }}>
                    {t('Reply')}
                </Typography>
                <TextField margin={'normal'} multiline minRows={3} maxRows={6} fullWidth value={replyText} onChange={(e) => (setReplyText(e.target.value))} />
                <Button sx={{mt: 3, mb: 2}} variant='contained' onClick={handleApplicantReply}>
                    {t('Submit')}
                </Button>
            </Box>
        </Modal>
    )
}

export default ApplicantModalReply;