import {
	TextField,
	Button,
	Typography,
	Paper,
	Modal,
    Divider,
	Box,
    TableRow,
    TableBody,
    Table,
    TableCell

} from '@mui/material';

import classesCard from "../../../../css/Card.module.scss";
import {useTranslation} from "react-i18next";

const ApplicantModalInfo = ({response, onOpen, onClose, onAccept, onReject}) => {

    const { t } = useTranslation();

    function formatCV(cvData) {
        let resultArr = []
        let resultArrResume = []

        let result = '';


            resultArr.push(
                <>
                    <Typography variant="h5" gutterBottom>{t('Info resume')}</Typography>

                </>
            );




        for (let key in cvData) {
            let key_title
            switch (key){


                case 'resume_download':
                    continue
                    break;

                case 'resume_type':
                    continue
                    break;
                case 'experience':
                    continue
                    break;
                case 'title':
                    key_title = t('specialization');
                    break;
                case 'name':
                    key_title = t('Name');
                    break;
                case 'surname':
                    key_title = t('Surname');
                    break;
                case 'age':
                    key_title = t('age');
                    break;
                case 'country':
                    key_title = t('country');
                    break;
                case 'email':
                    key_title = 'Email';
                    break;
                case 'phone':
                    key_title = t('phone');
                    break;
                case 'armenian_lang':
                    key_title = t('armenianLevel');
                    break;
                case 'english_lang':
                    key_title = t('englishLevel');
                    break;
                case 'russian_lang':
                    key_title = t('russianLevel');
                    break;
                case 'work_format':
                    key_title = t("Work Type");
                    break;
                default:
                    key_title = key
                    break
            }
            resultArrResume.push(
                <TableRow
                    key={key_title}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell>{key_title}</TableCell>
                    <TableCell>{cvData[key]}</TableCell>
                </TableRow>
            );
        }

        resultArr.push(
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                    {resultArrResume}
                </TableBody>
            </Table>
        )


        if(cvData['resume_download'] == '') {
            resultArr.push(
                <>
                    <br/>
                    <Divider/>
                    <br/>
                    <Typography variant="h5" gutterBottom>{t("Experience")}</Typography>

                </>
            );
        }




        for (let key in cvData['experience']) {
            resultArr.push(
                <Typography variant="h6" gutterBottom>{cvData['experience'][key]['company_name']}</Typography>
            )

            let table = []
            let table_exp = []

            result +=  `${cvData['experience'][key]['company_name']}\n`;
            for (let resume_stroke in cvData['experience'][key]) {
                let resume_stroke_title

                switch (resume_stroke){
                    case 'company_name':
                        continue;
                        break
                    case 'company_description':
                        resume_stroke_title = t("Company description");
                        break
                    case 'company_otrasl':
                        resume_stroke_title = t("Industry of the company");
                        break
                    case 'doljnost':
                        resume_stroke_title = t("Job title");
                        break
                    case 'start_date':
                        resume_stroke_title = t("Start date");
                        break
                    case 'job_desc':
                        resume_stroke_title = t("Description of work");
                        break
                    default:
                        resume_stroke_title = resume_stroke
                        break
                }

                table_exp.push(
                    <TableRow
                        key={resume_stroke_title}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell>{resume_stroke_title}</TableCell>
                        <TableCell>{cvData['experience'][key][resume_stroke]}</TableCell>
                    </TableRow>
                )


            }


            resultArr.push(
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                        {table_exp}
                    </TableBody>
                </Table>
            )

        }

        return (
            resultArr
        )
    }

    const downloadCV = async (id) => {
        const link = id;

        const anchor = document.createElement('a');
        anchor.href = link;
        anchor.target = '_blank';
        anchor.download = `${response.ID}-resume.pdf`;
        anchor.click();
    };

    return (
        <Modal open={onOpen} onClose={onClose} disableScrollLock={false}>
            {/* <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}> */}
            <Box sx={{ width: '80%', height: '80vh', overflow: 'scroll'}} className={classesCard.modal_sure}>
                <Typography variant="h5" component="div">
                    {response.reusume.name}
                </Typography>

                {response.reusume.resume_download == ''? (
                    <>
                <Typography variant="body2" color="text.secondary">
                    Email: {response.reusume.email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {t('phone')}: {response.reusume.phone}
                </Typography>
                    </>
                    ) : ''}
                <Box elevation={0} sx={{ my: 2 }}>
                    {/*<Typography component="div">*/}
                    {/*    Resume*/}
                    {/*</Typography>*/}
                    <Box multiline minRows={3} maxRows={6} fullWidth /> { formatCV(response.reusume) }</Box>

                {
                    response.reusume.resume_download && <Button variant="contained" onClick={() => downloadCV(response.reusume.resume_download)}>{t("Download CV")}</Button>
                }

                {response.applicant_status == 'pending' ? (
                    <>
                <Button 
                    sx={{margin: 2}}
                    variant="contained"
                    onClick={onReject}
                >
                    {t("Reject")}
                </Button>
                <Button
                    sx={{margin: 2}}
                    variant="contained"
                    onClick={onAccept}
                >
                    {t("Accept")}
                </Button>
                </>
                    ) : '' }
            </Box>
        </Modal>
    )
}

export default ApplicantModalInfo;