import i18n from 'i18next';

const logInValidate = (values) => {
  let errors = {};

  switch (true) {
    case !values.logInEmail:
      errors.logInEmail = i18n.t('emailRequired');
      break;
    case !/\S+@\S+\.\S+/.test(values.logInEmail):
      errors.logInEmail = i18n.t('emailInvalid');
      break;
    case !values.logInPassword:
      errors.logInPassword = i18n.t('passwordRequired');
      break;
    case values.logInPassword.length < 3:
      errors.logInPassword = i18n.t('passwordLength');
      break;
    default:
      break;
  }

  return errors;
};

export default logInValidate;
