export const handleFieldChange = (field, value, errors, errorCb, dataCb) => {
    let errorsFields = errors
    errorsFields[field] = false
    errorCb(errorsFields)

    dataCb((prevData) => ({
      ...prevData,
      [field]: value,
    }));
};

export const getDataToSend = (formData, companies, spec, city) => {
    const {
        title,
        job_responsibilities,
        required_qualifications,
        english,
        armenian,
        russian,
        type,
        experience,
        salary_type,
        min_salary,
        max_salary,
        currency,
        employment_type,
        work_type,
        remove_work_is_possible,
        job_status,
        title_am,
        title_en,
        description,
        description_am,
        description_en,
        m2a_profadditional_type_company,
        peculiarities_for_disabled_applicants,
        peculiarities_category_b_license,
        peculiarities_category_y_license,
        peculiarities_need_car,
        peculiarities_for_persons_from_14_yo,
        peculiarities_accommodation,
        peculiarities_powered,
        peculiarities_need_a_workpermit,
        peculiarities_piecework,
        peculiarities_can_go_out_tomorrow,
        peculiarities_relocation_package,
        peculiarities_possible_without_experience,
        other_region
    } = formData;
    const companyId = companies[0].id;

    return {
        company_id: companyId,
        title,
        job_responsibilities,
        required_qualifications,
        specializaciya : spec,
        job_city: [city],
        english: english,
        armenian: armenian,
        russian: russian,
        type: type,
        experience: experience,
        salary_type: salary_type,
        min_salary,
        max_salary,
        currency: currency,
        employment_type: employment_type,
        work_type: work_type,
        remove_work_is_possible,
        job_status,
        title_am,
        title_en,
        description,
        description_am,
        description_en,
        m2a_profadditional_type_company: m2a_profadditional_type_company,
        peculiarities_for_disabled_applicants: peculiarities_for_disabled_applicants,
        peculiarities_category_b_license: peculiarities_category_b_license,
        peculiarities_category_y_license: peculiarities_category_y_license,
        peculiarities_need_car: peculiarities_need_car,
        peculiarities_for_persons_from_14_yo: peculiarities_for_persons_from_14_yo,
        peculiarities_accommodation: peculiarities_accommodation,
        peculiarities_powered: peculiarities_powered,
        peculiarities_need_a_workpermit: peculiarities_need_a_workpermit,
        peculiarities_piecework: peculiarities_piecework,
        peculiarities_can_go_out_tomorrow: peculiarities_can_go_out_tomorrow,
        peculiarities_relocation_package: peculiarities_relocation_package,
        peculiarities_possible_without_experience: peculiarities_possible_without_experience,
        other_region: other_region
    };
}