import { Container, Grid, Paper } from '@mui/material'

import LogIn from './log-in';
import SignUp from './sign-up';
import Header from '../Header';

const AuthPage = () => {
    return(
        <>
            <Header />
            <Paper elevation={0} sx={{background: '#FEE8D4', width: '100%', height: '250px', position: 'absolute'}} />
            <Container sx={{position: 'relative'}}> 
                <Grid container spacing={10} sx={{marginTop: '20px'}}>
                    <LogIn />
                    <SignUp />
                </Grid>
            </Container>
        </>
    )
}

export default AuthPage;