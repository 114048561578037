// import axios from 'axios';
import axios from '../axios-config';
import { SERVER_URL } from '../../utils/const';
import {
    FETCH_VACANCIES,
    FETCH_VACANCIES_SUCCESS,
    FETCH_VACANCIES_ERROR,
    DELETE_VACANCY,
    DELETE_VACANCY_SUCCESS,
    DELETE_VACANCY_ERROR,
    FETCH_VACANCY,
    FETCH_VACANCY_ERROR,
    FETCH_SPECS,
    FETCH_SPECS_SUCCESS,
    FETCH_SPECS_ERROR,
    PATCH_VACANCY,
    PATCH_VACANCY_SUCCESS,
    PATCH_VACANCY_ERROR,
    FETCH_VACANCY_SUCCESS,
    FETCH_REGIONS,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_ERROR
} from '../reducers/vacancy-reducer';

const token = localStorage.getItem('token')

export const fetchVacancies = () => {
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_VACANCIES})
            const response = await axios.get(
                `${SERVER_URL}/acccompany/v1/my-jobs`,
                {headers: {Authorization: `Bearer ${token}`}}
            )
            dispatch({
                type: FETCH_VACANCIES_SUCCESS,
                payload: response.data,
            })
        } catch (e) {
            dispatch({
                type: FETCH_VACANCIES_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const fetchVacancy = (id) => {
    return async (dispatch) => {
        if (id !== undefined) {
            try {
                dispatch({type: FETCH_VACANCY})
                const response = await axios.get(
                    `${SERVER_URL}/acccompany/v1/my-jobs/${id}`,
                    {headers: {Authorization: `Bearer ${token}`}}
                )
                dispatch({
                    type: FETCH_VACANCY_SUCCESS,
                    payload: response.data
                })
            } catch (e) {
                dispatch({
                    type: FETCH_VACANCY_ERROR,
                    payload: e.response?.data?.message,
                })
            }
        }
    }
}

export const fetchSpecs = () => {
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_SPECS})
            const response = await axios.get(
                `${SERVER_URL}/acccompany/v1/my-jobs/spec`,
                {headers: {Authorization: `Bearer ${token}`}}
            )

            dispatch({
                type: FETCH_SPECS_SUCCESS,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: FETCH_SPECS_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const fetchRegions = () => {
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_REGIONS})
            const response = await axios.get(
                `${SERVER_URL}/acccompany/v1/my-jobs/cities`,
                {headers: {Authorization: `Bearer ${token}`}}
            )

            dispatch({
                type: FETCH_REGIONS_SUCCESS,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: FETCH_REGIONS_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const deleteVacancy = (id, e) => {
    return async (dispatch) => {
        try {
            dispatch({type: DELETE_VACANCY})
            const response = await axios.delete(
                `${SERVER_URL}/acccompany/v1/my-jobs/${id}`,

                {headers: {Authorization: `Bearer ${token}`}}
            )

            dispatch({type: DELETE_VACANCY_SUCCESS})
        } catch (e) {
            dispatch({
                type: DELETE_VACANCY_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const patchVacancy = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({type: PATCH_VACANCY})

            const response = await axios.patch(
                `${SERVER_URL}/acccompany/v1/my-jobs/${id}`,
                data,
                {headers: {Authorization: `Bearer ${token}`}}
            )

            window.location.replace('/profile/career/my-vacancies')
            dispatch({
                type: PATCH_VACANCY_SUCCESS
            })
        } catch (e) {
            dispatch({
                type: PATCH_VACANCY_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const postVacancy = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: PATCH_VACANCY})

            const response = await axios.post(
                `${SERVER_URL}/acccompany/v1/my-jobs/`,
                data,
                {headers: {Authorization: `Bearer ${token}`}}
            )


            window.location.replace('/profile/career/my-vacancies')

            dispatch({
                type: PATCH_VACANCY_SUCCESS
            })
        } catch (e) {
            dispatch({
                type: PATCH_VACANCY_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}
