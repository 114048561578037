import { useEffect, useState } from 'react'
import { Container, Box, Tabs, Tab } from '@mui/material'
import { Outlet, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Header from '../Header'

import { CATALOG_SECTION_ENDPOINT } from './catalog/utils/const'

const ProfilePage = () => {
    const { t } = useTranslation()
    const [currentTab, setCurrentTab] = useState(CATALOG_SECTION_ENDPOINT === 'career' ? 1 : 0)



    useEffect(() => {
        if (window.location.pathname === '/') {
            window.location.replace('/profile/catalog')
        }
    }, [])

    const handleChange = (e, value) => {
        let tabValue

        if (value === 0) {
            tabValue = 'catalog'
        }

        if (value === 1) {
            tabValue = 'career'
        }
        window.history.replaceState(null, '', `/profile/${tabValue}`);

        setCurrentTab(value);
    };

    return (
        <>
            <Header />
            <Box sx={{ width: '100%', bgcolor: 'background.paper',  }}>
                <Container sx={{ display: 'flex', alignItems: 'center'}} maxWidth={'lg'}>
                    <Tabs value={currentTab} onChange={handleChange} centered>
                        <Tab label={t('catalogPlacement')} to='/profile/catalog' component={Link} />
                        <Tab label={t('career')} to='/profile/career/new-vacancy' component={Link} />
                    </Tabs>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Link to='/logout' component="button" href="#">{t('logout')}</Link>
                </Container>
            </Box>
            <Outlet />
        </>
    )
}

export default ProfilePage;
