// import axios from 'axios';
import axios from '../axios-config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18next from 'i18next';

import { CONFIRM_EMAIL, CONFIRM_EMAIL_ERROR, CONFIRM_EMAIL_SUCCESS, FETCH_TOKEN, FETCH_TOKEN_ERROR, FETCH_TOKEN_SUCCESS, REGISTER, REGISTER_ERROR, REGISTER_SUCCESS, VALIDATE_TOKEN, VALIDATE_TOKEN_ERROR, VALIDATE_TOKEN_SUCCES } from '../reducers/auth-reducer';
import { SERVER_URL } from '../../utils/const';

export const fetchToken = (email, password) => {
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_TOKEN})
            const response = await axios.post(`${SERVER_URL}/jwt-auth/v1/token`, {username: email, password, role_access: 'profile_company'})
            dispatch({
                type: FETCH_TOKEN_SUCCESS,
                payload: response.data.token,
            })
            localStorage.setItem('token', response.data.token)
            toast.success(i18next.t('loginSuccess'));
            window.location.replace('/profile/catalog')
        } catch (e) {
            // toast.error(i18next.t('loginError'));
            toast.error(i18next.t(e.response?.data?.message));
            dispatch({
                type: FETCH_TOKEN_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const validateToken = (token) => {
    return async (dispatch) => {
        try {
            dispatch({type: VALIDATE_TOKEN})
            await axios.post(
                `${SERVER_URL}/jwt-auth/v1/token/validate`,
                null,
                {headers: {Authorization: `Bearer ${token}`}}
            )
            dispatch({type: VALIDATE_TOKEN_SUCCES})
        } catch (e) {
            dispatch({
                type: VALIDATE_TOKEN_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const register = (email, password, companyName) => {
    return async (dispatch) => {
        try {
            dispatch({type: REGISTER})
            await axios.post(
                `${SERVER_URL}/acccompany/v1/register`,
                {email, password, company_name: companyName}
            )
            toast.success(i18next.t('registrationSuccess'));
            dispatch({type: REGISTER_SUCCESS})
        } catch (e) {
            // toast.error(i18next.t('registrationError'));
            toast.error(e.response?.data?.message);
            dispatch({
                type: REGISTER_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}

export const confirmMail = (token) => {
    return async (dispatch) => {
        try {
            dispatch({type: CONFIRM_EMAIL})
            await axios.post(
                `${SERVER_URL}/acccompany/v1/confirm-email`,
                {tempToken: token}
            )
            dispatch({type: CONFIRM_EMAIL_SUCCESS})
            toast.success(i18next.t('confirmSuccessLoginPlease!'));
            // window.location.replace('profile/career/new-vacancy')
        } catch (e) {
            dispatch({
                type: CONFIRM_EMAIL_ERROR,
                payload: e.response?.data?.message,
            })
        }
    }
}