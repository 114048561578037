import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmMail } from '../../store/action-creators/auth';

const EmailCheck = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { token } = useParams();
    
    useEffect(() => {
        dispatch(confirmMail(token))
        navigate('/');
    }, []);

    return <div>Email confirmation...</div>;
};

export default EmailCheck;