import {
    Container,
    Typography,
    Snackbar,
    Alert,
    Box
 } from '@mui/material';

import { MAX_ACTIVE_VACANCIES } from '../../../../utils/const';
import {useTranslation} from "react-i18next";
const VacancyCounter = ({activeVacancies, onAlertOpen, onAlertClose}) => {
    const { t } = useTranslation();
    return (
        <Box sx={{marginTop: '20px'}}>
            <Typography variant="body1">
                {t('activeVacancies')}: {activeVacancies.length}/{MAX_ACTIVE_VACANCIES}
            </Typography>
            <Snackbar open={onAlertOpen} autoHideDuration={6000} onClose={onAlertClose}>
                <Alert onClose={onAlertClose} severity="error" sx={{ width: '100%' }}>
                    {t('youHaveAlreadyPostedTheMaximumNumberOfVacancies')}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default VacancyCounter;