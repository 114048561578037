import React, { useState } from 'react';
import {
    Box,
    Container,
    Toolbar,
    IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import enFlag from '../locales/en/enFlag.png';
import amFlag from '../locales/am/amFlag.png';
import ruFlag from '../locales/ru/ruFlag.png';
import logo from '../logo.png'
import classes from '../css/Header.module.scss'

const Header = () => {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const handleLanguageChange = (lang) => {
        setCurrentLanguage(lang);
        i18n.changeLanguage(lang);
        localStorage.setItem('selectedLanguage', lang);
    };
    return (
       
        <Box sx={{ flexGrow: 1 }} >
            <div className={classes.header}>
            <Container sx={{position: 'relative'}}> 
                <Toolbar>
                    <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '16px' }} />
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={() => handleLanguageChange('en')} disabled={currentLanguage === 'en'}>
                        <img src={enFlag} alt="English" style={{ width: '24px', height: 'auto' }} />
                    </IconButton>
                    <IconButton onClick={() => handleLanguageChange('am')} disabled={currentLanguage === 'am'}>
                        <img src={amFlag} alt="Հայերեն" style={{ width: '24px', height: 'auto' }} />
                    </IconButton>
                    <IconButton onClick={() => handleLanguageChange('ru')} disabled={currentLanguage === 'ru'}>
                        <img src={ruFlag} alt="Русский" style={{ width: '24px', height: 'auto' }} />
                    </IconButton>
                </Toolbar>
                </Container>
            </div>
        </Box>
        
    );
};
export default Header;