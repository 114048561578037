import { Grid, Card, CardMedia, CardContent, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import icon from '../../../../white icon-20.png'

const CompanyCard = ({ company, onEdit }) => {
    const bgStyle = company.img[0]
        ? { pt: '56.25%' }
        : { pt: '56.25%', backgroundColor: '#000', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' };

    return (
        <Grid item xs={6}>
            <Card
                sx={{
                    position: 'relative',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <CardMedia
                    component="div"
                    sx={bgStyle}
                    image={company.img[0] || icon}
                />
                <CardContent sx={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    color: '#fff',
                    bgcolor: 'rgba(0, 0, 0, 0.4)'
                }}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {company.title}
                    </Typography>
                </CardContent>
                {company.status != 'draft' && company.moderation != 'moderation' ? (
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: '#fff',
                        backgroundColor: 'rgba(0, 0, 0, 0.4)'
                    }}
                    onClick={() => onEdit(company)}
                >
                    <EditIcon />
                </IconButton>
                    ) : (
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: '#fff',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)'
                        }}

                    >
                        Moderation
                    </IconButton>
                ) }
            </Card>
        </Grid>
    );
}

export default CompanyCard;
