import { useEffect, useState } from 'react';
import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MAX_ACTIVE_VACANCIES } from '../../../../utils/const';
import { useDispatch, useSelector } from 'react-redux';

import { fetchVacancies } from '../../../../store/action-creators/vacancy';

import M2ALoader from '../../../templateParts/loader';
import VacancyCounter from '../templates/vacancy-counter';
import VacancyForm from '../vacancy-form/vacancy-form';

const selectVacancies = state => state.vacancy.vacancies
const selectIsLoading = store => store.vacancy.isLoadding

const NewVacancy = () => {
    const vacancies = useSelector(selectVacancies)
    const isLoading = useSelector(selectIsLoading)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [isFormOpened, setIsFormOpened] = useState(false)
    const [isMaxVacanciesAlertOpened, setIsVacanciesAlertOpened] = useState(false);
    const [activeVacancies, setActiveVacancies] = useState([])

    useEffect(() => {
        dispatch(fetchVacancies())
    },[dispatch])

    useEffect(() => {
        if (vacancies[0]) {
            setActiveVacancies(vacancies[0].jobs.filter(vacancy => vacancy.status === 'publish'))
        }
    },[vacancies])

    const handleAddNewVacancy = () => {
        if (activeVacancies.length >= MAX_ACTIVE_VACANCIES) {
            setIsVacanciesAlertOpened(true);
        } else {
            setIsFormOpened(true)
        }
    };

    if (isLoading) {
        return <M2ALoader />
    }

    return (
        <>
            <Box maxWidth={'xs'}>
                {!isFormOpened 
                ? (<>
                    <Button
                            fullWidth
                            variant='contained'
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleAddNewVacancy}
                        >

                            {t('addNewVacancy')}
                        </Button>

                        <VacancyCounter
                            activeVacancies={activeVacancies}
                            onAlertOpen={isMaxVacanciesAlertOpened}
                            onAlertClose={() => setIsVacanciesAlertOpened(false)}
                        />
                </>) 
                : (<VacancyForm 
                        editorType={'copy'}
                        onClose={(() => setIsFormOpened(false))}
                        updateCb={() => dispatch(fetchVacancies())}
                />)}
            </Box>
        </>
    );
}

export default NewVacancy;