import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';

import { fetchCompanies } from '../../../store/action-creators/company';
import NewCompanySection from './new-company/new-company-section'
import M2ALoader from '../../templateParts/loader';
import CatalogList from './catalog-list/catalog-list';

const selectCompanies = state => state.company.companies;
const selectIsCompaniesLoading = state => state.company.isCompaniesLoading;

const CatalogSection = () => {
    const dispatch = useDispatch()
    const companies = useSelector(selectCompanies)
    // const companies = []
    const isCompaniesLoading = useSelector(selectIsCompaniesLoading)

    useEffect(()=>{
        dispatch(fetchCompanies())
    }, [dispatch])

    if (isCompaniesLoading) {
        return <M2ALoader />
    }

    return (
        <Container sx={{ py: 8 }} maxWidth='lg'>
            <Grid container spacing={4}>
                {companies.length
                ? <CatalogList items={companies} />
                : <NewCompanySection />}
            </Grid>
        </Container>
    )
}

export default CatalogSection;