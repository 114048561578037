export const REQUIRED_FIELDS = [
    'title',
    'job_responsibilities',
    'required_qualifications',
    'specializaciya',
    'english',
    'armenian',
    'russian',
    'type',
    'salary_type',
    'min_salary',
    'currency',
    'employment_type',
    'work_type',
    'remove_work_is_possible',
    'title_am',
    'title_en',
    'description',
    'description_am',
    'description_en'
]