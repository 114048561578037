import React, { useEffect, useState } from 'react'
import { Grid, Button, FormControl, InputLabel, Select, MenuItem, TextField, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'

const SocialLinksInput = ({data, cb}) => {
    const { t } = useTranslation()
    const [networks, setNetworks] = useState([])

    const socialNetworks = [
        'Facebook',
        'Twitter',
        'Instagram',
        'YouTube',
        'Snapchat',
        'Twitter',
        'Tumblr',
        'Reddit',
        'LinkedIn',
        'Pinterest',
        'DeviantArt',
        'VK',
        'SoundCloud',
        t('website'),
        t('other'),
    ]

    useEffect(() => {
        if (data !== undefined) {
            setNetworks(data)
        }
    },[])

    const handleNetworkChange = (index, event) => {
        const updatedNetworks = [...networks]
        updatedNetworks[index].network = event.target.value
        setNetworks(updatedNetworks)
        cb(networks)
    }

    const handleUrlChange = (index, event) => {
        const updatedNetworks = [...networks]
        updatedNetworks[index].url = event.target.value
        setNetworks(updatedNetworks)
        cb(networks)
    }

    const addNetwork = () => {
        setNetworks([...networks, { network: '', url: '' }])
        cb(networks)
    }

    const removeNetwork = (index) => {
        const updatedNetworks = [...networks]
        updatedNetworks.splice(index, 1)
        setNetworks(updatedNetworks)
        cb(networks)
    }

    return (
        <>
        {networks?.map((network, index) => (
            <Grid container spacing={2} key={index} sx={{marginTop: 2}}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>{t('network')}</InputLabel>
                        <Select
                        value={network.network || []}
                        onChange={(event) => handleNetworkChange(index, event)}
                        >
                        {socialNetworks.map((option, optionIndex) => (
                            <MenuItem key={optionIndex} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        fullWidth
                        label={t('url')}
                        value={network.url}
                        onChange={(event) => handleUrlChange(index, event)}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => removeNetwork(index)}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        ))}
        <Button
            sx={{mt: 2, mb: 2}}
            fullWidth
            variant="contained"
            onClick={addNetwork}
        >
            {t('add')}
        </Button>
        </>
    )
}

export default SocialLinksInput;
