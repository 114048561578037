import { useState } from 'react'
import CompanyCard from './company-card'
import CompanyInfoForm from '../company-form/company-info-form'

const CatalogList = ({items}) => {
    const [isEditorOpened, setIsEditorOpened] = useState(false)
    const [editCardId, setEditCardId] = useState('')

    const handleEditClick = async (item) => {
        setEditCardId(item.id)
        setIsEditorOpened(true)
    }

    return (
        <>
            {isEditorOpened
                ? <CompanyInfoForm
                    type={'edit'}
                    companyId={editCardId}
                    category={null}
                    onClose={() => setIsEditorOpened(false)}
                />
                : items.map((item) => (
                    <CompanyCard 
                        key={item.id}
                        company={item}
                        onEdit={handleEditClick}
                    />
                ))
            }
        </>
    )
}

export default CatalogList