export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'

export const PATCH_USER = 'PATCH_USER'
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS'
export const PATCH_USER_ERROR = 'PATCH_USER_ERROR'

const initialState = {
    user: {},
    isLoading: false,
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER:
            return {...state, isLoading: true}
        case FETCH_USER_SUCCESS:
            return {...state, isLoading: false, user: action.payload}
        case FETCH_USER_ERROR:
            return {...state, isLoading: false, error: action.payload}
        case PATCH_USER:
            return {...state, isLoading: true}
        case PATCH_USER_SUCCESS:
            return {...state, isLoading: false}
        case PATCH_USER_ERROR:
            return {...state, isLoading: false}
        default:
            return state
    }
}